import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import RealEstateInnerDetails from "./pages/real-estate-inner-details";
import Search from "./pages/search-vehicle";
import CreateRealEstateAd from "./pages/create-realestate-ad";
import CreateVehicleAd from "./pages/create-vehicle-ad";
import { Navigate } from "react-router-dom";
import VehiclePreview from "./pages//vehicle-preview";
import RealEstatePreview from "./pages//realestate-preview";
import ProfilePage from "./pages/my-profile";
import PrivacyPolicy from "./pages/privacy-policy";
import Faq from "./pages/faqs";
import About from "./pages/about-us";
import ManageAds from "./pages/ad-management";
import Favorite from "./pages/favourites";
import ReportProblem from "./pages/report-problem";
import Home from "./pages/home";
import Vehicle from "./pages/vehicle";
import RealEstate from "./pages/real-estate/index";
import HondaCarForSale from './pages/car-inner-details/index';
import ProtectedRoutes from "./utils/protected";
import EditState from "./pages/edit-realestate";
import EditVehicle from "./pages/edit-vehicle";
import SearchRealEstate from './pages/search-realestate/index'
import strings from './utils/locals/languages'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReportAd from './pages/report-ad'
import TermsOfUse from './pages/terms-of-use'
import { useEffect, useState } from "react";
import { onMessageListener } from "./firebase";
import { Toast } from "react-bootstrap";
import NotFoundPage from "./components/NotFound";
import SelectPackage from "./pages/select-package";
import Packages from "./pages/packages";


function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
  }).catch(err => console.log('failed: ', err));

  return (
    <div className="App"
    >
      <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
        position: 'absolute',
        top: 20,
        right: 20,
        minWidth: 200
      }}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded mr-2"
            alt=""
          />
          <strong className="mr-auto">{notification.title}</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>{notification.body}</Toast.Body>
      </Toast>
      <BrowserRouter strings={strings}>
        <Routes>
          <Route path="*" element={<Navigate to="/not-found" />} />
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route path="/vehicle-details/:id" element={<HondaCarForSale />} />
          <Route path="/vehicle" element={<Vehicle />} />
          <Route path="/search-vehicle/:id" element={<Search />} />
          <Route path="/search-realestate/:id" element={<SearchRealEstate />} />
          <Route path="/real-estate-details/:id" element={<RealEstateInnerDetails />} />
          <Route path="/reportAd" element={<ReportAd />} />

          <Route path="/realstate" element={<RealEstate />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route element={<ProtectedRoutes />}>

            <Route path="/edit-vehicle" element={<EditVehicle />} />


            <Route path="/create-realestate-ad" element={<CreateRealEstateAd />} />
            <Route path="/edit-realestate" element={<EditState />} />


            <Route path="/create-vehicle-ad" element={<CreateVehicleAd />} />
            <Route
              path="/vehicle-preview"
              element={<VehiclePreview />}
            />

            <Route
              path="/realestate-preview"
              element={<RealEstatePreview />}
            />

            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/termsofuse" element={<TermsOfUse />} />
            
            <Route path="/faq" element={<Faq />} />

            {/* <Route path="/vehicleopen" element={<VehicleOpen />} /> */}
            <Route path="/ads-management" element={<ManageAds />} />
            <Route path="/favorites" element={<Favorite />} />
            <Route path="/reportproblem" element={<ReportProblem />} />
            <Route path="/reportAd" element={<ReportAd />} />
            <Route path="/select-package" element={<SelectPackage />} />
            <Route path="/packages" element={<Packages />} />
          </Route>

        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
