import { englishEnvironment, russianEnvironment, uzbekEnvironment } from "../translation-files/realestate";

export const translateEnvironment = async (data, source, target) => {
    try {
        if (source === "en") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = englishEnvironment.find(detail => detail.fieldId === dataItem.fieldName);
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };
    
                    return updatedData;
                }
                // If no match is found, return the original data item
                return dataItem;
            });
    
            const russianData = []
            updatedFeatures.map((item) => {
                const matchedData = russianEnvironment.find(detail => detail.fieldId === item.fieldId);
    
                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };
    
                    russianData.push(updatedData)
                }
            })
    
            const uzbekData = []
            updatedFeatures.map((item) => {
                const matchedData = uzbekEnvironment.find(detail => detail.fieldId === item.fieldId);
    
                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };
    
                    uzbekData.push(updatedData)
                }
            })
    
            return {
                englishFeatures: updatedFeatures,
                russianFeatures: russianData,
                uzbekFeatures: uzbekData
            };
        } else if (source === "ru") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = russianEnvironment.find(detail => 
                    detail.fieldName.replace(/\s/g, '').toLowerCase() === dataItem.fieldName.replace(/\s/g, '').toLowerCase()
                  );
                  
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };
    
                    return updatedData;
                }
                // If no match is found, return the original data item
                return dataItem;
            });
    
            const englishData = []
            updatedFeatures.map((item) => {
                const matchedData = englishEnvironment.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  
                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };
    
                    englishData.push(updatedData)
                }
            })
    
            const uzbekData = []
            updatedFeatures.map((item) => {
                const matchedData = uzbekEnvironment.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  
    
                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };
    
                    uzbekData.push(updatedData)
                }
            })
    
            return {
                englishFeatures: englishData,
                russianFeatures: updatedFeatures,
                uzbekFeatures: uzbekData
            };
        } else if (source === "uz") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = uzbekEnvironment.find(detail => 
                    detail.fieldName.replace(/\s/g, '').toLowerCase() === dataItem.fieldName.replace(/\s/g, '').toLowerCase()
                  );
                  
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };
    
                    return updatedData;
                }
                // If no match is found, return the original data item
                return dataItem;
            });
    
            const englishData = []
            updatedFeatures.map((item) => {
                const matchedData = englishEnvironment.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  
    
                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };
    
                    englishData.push(updatedData)
                }
            })
    
            const russianData = []
            updatedFeatures.map((item) => {
                const matchedData = russianEnvironment.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  
    
                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };
    
                    russianData.push(updatedData)
                }
            })
    
            return {
                englishFeatures: englishData,
                russianFeatures: russianData,
                uzbekFeatures: updatedFeatures
            };
        }
     } catch (error) {
        return []; 
    }

}