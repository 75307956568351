import React from 'react'
import strings from '../utils/locals/languages'

const NotificationsConvas = ({ notificationData }) => {
    return (
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel">{strings.notif}</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                {
                    notificationData?.map((data, index) => (
                        <div key={index}
                            className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""
                                }`}
                        >
                            <p style={{ fontWeight: 'bold' }}>{index + 1}. {data.title}</p>
                            <p>{data.message}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default NotificationsConvas