import React, { useState, useEffect } from "react";

import mobile from "../../images/Mobil.png";
import appstore from "../../images/appstore.png";
import googleplay from "../../images/googleplay.png";
import calendar from "../../images/calendar.svg";

import Group11921 from "../../images/Group 11921.svg";
import Group11922 from "../../images/Group 11922.svg";
import Group11923 from "../../images/Group 11923.svg";
import Group11924 from "../../images/Group 11924.svg";
import area from "../../images/ar.svg";
import beds from "../../images/bedss.svg";
import wash from "../../images/wshs.svg";
import year from "../../images/yeear.svg";
import engineSize from "../../images/engine.svg";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ClipLoader } from 'react-spinners'

import "./index.css";
import strings from "../../utils/locals/languages";
import { useSelector } from "react-redux";
import { baseURL1, realEstateId, vehicleId } from "../../utils/baseUrl";
import apiClient from "../../utils/apiClient";
import Footer from "../../components/Footer";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


const Body = ({ search }) => {
  const user = Cookies.get("userDetails");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [realEstatesLoading, setRealEstatesLoading] = useState(true);
  const [reLoad, setReLoad] = useState(0);
  const language = useSelector((state) => state.language);
  const token = Cookies.get("token");
  const [userId, setUserId] = useState("");
  const [adsByCity, setAdsByCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedCityData, setSelectedCityData] = useState([])
  const [partners, setPartners] = useState([])
  const [internalAds, setInternalAds] = useState([])
  const [citiesLoading, setCitiesLoading] = useState(true)


  const addToFavorites = (event, adId) => {
    event.stopPropagation();

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        // Delay the execution by 2 seconds
        setTimeout(() => {
          apiClient
            .get(
              `${baseURL1}/v1/adds/search/?section=${vehicleId}&language=${strings._language}`
            )
            .then((response) => {
              setVehicleData(response.data.data);
              setIsLoading(false);
            })
            .catch((err) => { });

          apiClient
            .get(
              `/v1/adds/search/?section=${realEstateId}&language=${strings._language}`
            )
            .then((response) => {
              setData(response.data.data);
              setRealEstatesLoading(false);
            })
            .catch((err) => { });
        }, 2000); // 2000 milliseconds = 2 seconds
      })
      .catch((err) => { });
  };

  useEffect(() => {
    adsByCity?.forEach((item) => {
      if (item.city === selectedCity) {
        setSelectedCityData(item.ads);
      }
    });
  }, [selectedCity]);


  useEffect(() => {
    setIsLoading(true);
    setRealEstatesLoading(true);
    apiClient
      .get(
        `${baseURL1}/v1/adds/search/?section=${vehicleId}&language=${strings._language}`
      )
      .then((response) => {
        setVehicleData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });

    // for cities
    apiClient
      .get(`${baseURL1}/v1/adds/getAdsByCity?language=${strings._language}`)
      .then((response) => {
        getCities(response.data.addsByCity)
      })
      .catch((err) => { });

    apiClient
      .get(
        `/v1/adds/search/?section=${realEstateId}&language=${strings._language}`
      )
      .then((response) => {
        setData(response.data.data);
        setRealEstatesLoading(false);
      })
      .catch((err) => { });
  }, [strings._language]);

  const getInternalAds = async () => {
    try {
      const response = await apiClient.get('v1/user/get-internal-ads')

      setInternalAds(response.data)
    } catch {
      setInternalAds([])
    }
  }

  useEffect(() => {
    let filteredData = vehicleData.filter((item) => {
      return search !== ""
        ? item.basicInformation.title
          .toLowerCase()
          .includes(search.toLowerCase())
        : item;
    });

    let filteredRealEstateData = data.filter((item) => {
      return search !== ""
        ? item.basicInformation.title
          .toLowerCase()
          .includes(search.toLowerCase())
        : item;
    });

    setVehicleData(filteredData);
    setData(filteredRealEstateData);
  }, [search]);

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await apiClient.get('/v1/user/view-partners?location=homePage');
      setPartners(response.data.partners);
    } catch (err) {
      setPartners([])
    }
  };


  useEffect(() => {
    fetchPartners()
    getInternalAds()
  }, [])

  const getCities = async (adsCities) => {
    try {
      const response = await apiClient.get(`/v1/adds/ads-cities`);
      const cities = response.data.cities;
      const updatedCities = adsCities.map(myCity => {
        const cityWithImage = cities.find(city => city.title === myCity.city.toLowerCase());
        return {
          ...myCity,
          imageUrl: cityWithImage ? cityWithImage.imageUrl : null
        };
      });

      setAdsByCity(...[updatedCities]);

      setCitiesLoading(false);
    } catch (error) {
      console.error('Error fetching cities or processing ads:', error);
    }
  };


  const itemsToShow = 5

  const itemWidth = 300
  const [scrollPosition, setScrollPosition] = useState(0);

  const isVehicleEndReached = (vehicleData.length * 250)
  const isVehicleStartReached = scrollPosition === 0;

  const vehicleNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      scrollPosition + itemWidth,
      ((vehicleData.length + 2) - itemsToShow) * itemWidth
    );

    if (isVehicleEndReached >= scrollPosition) {
      setScrollPosition(nextPosition);
    }
  };

  const vehiclePrevButtonClickHandler = () => {
    const prevPosition = Math.max(scrollPosition - itemWidth, 0);
    setScrollPosition(prevPosition);
  };


  const [realEstateScrollPosition, setRealEstateScrollPosition] = useState(0);

  const isRealEstateEndReached = (data.length * 250)
  const isRealEstateStartReached = realEstateScrollPosition === 0;

  const realEstateNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      realEstateScrollPosition + itemWidth,
      ((data.length + 2) - itemsToShow) * itemWidth
    );

    if (isRealEstateEndReached >= realEstateScrollPosition) {
      setRealEstateScrollPosition(nextPosition);
    }
  };

  const realEstatePrevButtonClickHandler = () => {
    const prevPosition = Math.max(realEstateScrollPosition - itemWidth, 0);
    setRealEstateScrollPosition(prevPosition);
  };

  return (
    <div className=" space-y-10 ">

      {/* section 2 */}
      <div className="container">
        <div className="row">
          <Carousel
            showIndicators={false}
            showThumbs={false}
            interval={3000}
            autoPlay={true}
            infiniteLoop
          >

            {
              internalAds.filter((ad) => ad.imageUrl).map((item, index) => (
                <div className="md:h-[200px] h-[100px]">
                  <img src={item?.imageUrl} className="rounded-md h-full " />

                </div>
              ))
            }
          </Carousel>

        </div>
      </div>
      {/* section 3 */}

      {/* <div className="bg-success p-2 w-[100px] ml-auto mr-auto text-center text-white cursor-pointer"
      onClick={handlePayment}
      >
        Pay
      </div> */}


      <hr className=" opacity-10 " />

      {/* section 4 */}
      <div className=" mx-auto ">
        {isLoading ? (


          <div className="container ">
            <div>
              {" "}
              <Skeleton
                height={30}
                width={350}
                style={{
                  marginBottom: "24px",
                }}
              />
            </div>

            <div className="container">
              <div className="grid grid-cols-1  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-3 justify-center">
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <div
                    className=" shadow-xl rounded-xl cursor-pointer "
                    key={index}
                  >
                    <div className="relative h-40">
                      <Skeleton
                        height={160}
                        style={{
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      />
                    </div>
                    <div className="px-[10px] mt-2">
                      <div
                        className="flex items-center justify-between"
                      >
                        <Skeleton height={15} width={70} />
                        <Skeleton height={15} width={50} />
                      </div>
                      <div className="flex gap-2 ">
                        <Skeleton height={10} width={100} />
                      </div>
                      <div
                        className="flex mb-1  text-[10px] justify-between items-center text-gray-400"
                      >
                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="container ">

            <div className="flex justify-between ">
              <h4 className="uppercase font-semibold text-[20px] mb-4">
                {strings.recommendedVehicles}
              </h4>
              <div className="flex">
                <ArrowBackIosNewIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 bg-white `}
                  onClick={() => vehiclePrevButtonClickHandler()}
                />
                <ArrowForwardIosIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 ml-1 bg-white `}
                  onClick={() => vehicleNextButtonClickHandler()}
                />
              </div>
            </div>




            <div className="">
              {vehicleData.length === 0 ? (
                <div className="flex justify-center">
                  <h3>{strings.norec}</h3>
                </div>
              ) : (
                <div
                  className='flex gap-4 p-2 bg-transparent overflow-y-auto md:overflow-hidden'
                >
                  {vehicleData.map((item, index) => (
                    <div
                      className="w-[300px] flex flex-col shadow-md rounded-xl  cursor-pointer "
                      style={{
                        display: 'flex',
                        transition: 'transform 0.3s',
                        transform: `translateX(-${scrollPosition}px)`,
                      }}
                      onClick={() => {
                        navigate(`/vehicle-details/${item._id}`, {
                          state: {
                            details: item,
                          },
                        });
                      }}
                    >
                      <div className="relative h-[170px] w-[100%]">
                        {!item?.fav?.includes(userId) ? (
                          <FavoriteBorderOutlinedIcon
                            className="absolute items-content-end w-12"
                            style={{
                              right: "8px",
                              color: "#F37521",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                addToFavorites(event, item._id);
                              }
                            }}
                          />
                        ) : (
                          <FavoriteOutlinedIcon
                            className="absolute items-content-end w-12 opacity-100"
                            style={{
                              right: "8px",
                              color: "red",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                addToFavorites(event, item?._id);
                              }
                            }}
                          />
                        )}
                        <img
                          className=" rounded-t-2xl"
                          src={item?.coverPhoto}
                          alt="random"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            // borderTopRightRadius: 12,
                            // borderTopLeftRadius: 12,
                          }}
                        />
                      </div>
                      <div className="px-[10px] mt-2">
                        <div
                          className="flex items-center justify-between"
                        
                        >
                          <div>
                            <h3 className="font-[500] text-sm ">
                              {item?.basicInformation?.title
                                .slice(0, 12)
                                .split(" ")
                                .slice(0, 5)
                                .join(" ")}
                            </h3>
                          </div>

                          <div className="flex " style={{ color: "#F37521" }}>
                            <h1 className="font-bold text-sm ">
                              {item?.basicInformation?.price?.currency} &nbsp;
                            </h1>
                            <h1 className="font-bold text-sm">
                              {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </h1>

                          </div>
                        </div>
                        <div className="flex gap-2 -mb-2">
                          <h1 className="text-[9px] font-[350] text-gray-400 ">
                            {item?.basicInformation?.description?.slice(0, 25)}
                          </h1>
                        </div>
                        <div
                          className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"
                        
                        >
                          <div className="flex gap-2 items-center">
                            <img src={year} alt="year" className="h-3 w-3" />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.details[0]?.value}
                            </p>
                          </div>

                          <div className="flex gap-2 items-center">
                            <img
                              src={engineSize}
                              alt="year"
                              className="h-3 w-3"
                            />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.details
                                ?.filter((item) =>
                                  [
                                    "Engine Size",
                                    "Размер двигателя",
                                    "Dvigatel hajmi",
                                  ].includes(item.fieldName)
                                )
                                .map((filteredItem, index) => (
                                  <span key={index}>{filteredItem?.value}</span>
                                ))}
                            </p>
                          </div>

                          <div className="flex gap-2 items-center">
                            <img
                              src={calendar}
                              color="gray"
                              alt="year"
                              className="h-3 w-3 opacity-60"
                            />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.KM}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <hr className=" opacity-10 " />

      <div className=" mx-auto">
        {realEstatesLoading ? (
        
          <div className="container ">
            <div>
              {" "}
              <Skeleton
                height={30}
                width={350}
                style={{
                  marginBottom: "24px",
                }}
              />
            </div>

            <div className="">
              <div className="grid grid-cols-1  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-3 justify-center">
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <div
                    className="c shadow-xl rounded-xl  cursor-pointer "
                    key={index}
                  >
                    <div className="relative h-40">
                      <Skeleton
                        height={160}
                        style={{
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      />
                    </div>
                    <div className="px-[10px] mt-2">
                      <div
                        className="flex items-center justify-between"
                      >
                        <Skeleton height={15} width={70} />
                        <Skeleton height={15} width={50} />
                      </div>
                      <div className="flex gap-2 ">
                        <Skeleton height={10} width={100} />
                      </div>
                      <div
                        className="flex mb-1  text-[10px] justify-between items-center text-gray-400"
                      >
                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>

                        <div className="flex gap-2 items-center">
                          <Skeleton height={10} width={20} />
                          <Skeleton height={10} width={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="container ">
            <div className="flex justify-between ">
              <h4 className="uppercase font-semibold text-[20px] mb-4">
                {strings.recommendedRealEstates}
              </h4>
              <div className="flex">
                <ArrowBackIosNewIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 bg-white `}
                  onClick={() => realEstatePrevButtonClickHandler()}
                />
                <ArrowForwardIosIcon
                  className={`cursor-pointer  rounded-full text-xl p-1 ml-1 bg-white `}
                  onClick={() => realEstateNextButtonClickHandler()}
                />
              </div>
            </div>

            {data.length === 0 ? (
              <div className="flex justify-center">
                <h3>{strings.norec}</h3>
              </div>
            ) : (
              <div className="">
                <div
                  className='flex gap-4 p-2 bg-transparent overflow-y-auto md:overflow-hidden hours-container'
                >
                  {data.map((item, index) => (
                    <div
                      className="w-[300px] flex flex-col shadow-md rounded-xl  cursor-pointer "
                      style={{
                        display: 'flex',
                        transition: 'transform 0.3s',
                        transform: `translateX(-${realEstateScrollPosition}px)`,
                      }}
                      onClick={() => {
                        navigate(`/real-estate-details/${item._id}`, {
                          state: {
                            details: item,
                          },
                        });
                      }}
                    >
                      <div
                        className="relative  h-[170px] w-[100%]"
                      // style={{ height: "200px", overflow: "hidden" }}
                      >
                        {!item?.fav?.includes(userId) ? (
                          <FavoriteBorderOutlinedIcon
                            className="absolute items-content-end w-12"
                            style={{
                              right: "8px",
                              color: "#F37521",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                addToFavorites(event, item._id);
                              }
                            }}
                          />
                        ) : (
                          <FavoriteOutlinedIcon
                            className="absolute items-content-end w-12"
                            style={{
                              right: "8px",
                              color: "red",
                              backgroundColor: "white",
                              borderRadius: 100,
                              padding: 3,
                              top: 8,
                            }}
                            onClick={(event) => {
                              if (user) {
                                addToFavorites(event, item._id);
                              }
                            }}
                          />
                        )}
                        <img
                          className="rounded-t-2xl"
                          src={item?.coverPhoto}
                          alt="random"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            // borderTopRightRadius: 12,
                            // borderTopLeftRadius: 12,
                          }}
                        />
                      </div>
                      <div
                        className="px-[10px] mt-2"
                        style={{ overflow: "hidden" }}
                      >
                        <div
                          className="flex items-center justify-between "
                        
                        >
                          <div>
                            <h3 className="font-[500] text-sm ">
                              {item?.basicInformation?.title.slice(0, 12)}
                            </h3>
                          </div>
                          <div className="flex " style={{ color: "#F37521" }}>
                            <h1 className="font-bold text-sm ">
                              {item?.basicInformation?.price?.currency} &nbsp;
                            </h1>
                            <h1 className="font-bold text-sm ">
                              {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </h1>
                          </div>
                        </div>
                        <div className="flex gap-2 -mb-2">
                          <h1 className="text-[9px] font-[350] text-gray-500 ">
                            {item.basicInformation.description.slice(0, 25)}
                          </h1>
                        </div>
                        <div
                          className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"
                        
                        >
                          <div className="flex gap-2 items-center">
                            <img src={beds} alt="year" className="h-3 w-3" />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.details
                                .filter((item) =>
                                  [
                                    "Bedrooms",
                                    "Спальни",
                                    "Yotoq xonalari",
                                  ].includes(item?.fieldName)
                                )
                                .map((filteredItem, index) => (
                                  <span key={index}>
                                    {filteredItem?.value === ""
                                      ? 1
                                      : filteredItem?.value}
                                  </span>
                                ))}
                            </p>
                          </div>

                          <div className="flex gap-2 items-center">
                            <img src={area} alt="year" className="h-3 w-3" />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.area?.net}sq.ft
                            </p>
                          </div>

                          <div className="flex gap-2 items-center">
                            <img
                              src={wash}
                              color="gray"
                              alt="year"
                              className="h-3 w-3 opacity-60"
                            />
                            <p className=" mt-[17px]">
                              {item?.basicInformation?.details
                                .filter((item) =>
                                  [
                                    "Bathrooms",
                                    "Ванные комнаты",
                                    "Hammomlar",
                                  ].includes(item.fieldName)
                                )
                                .map((filteredItem, index) => (
                                  <span key={index}>{filteredItem.value}</span>
                                ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <hr />

      <div className="container">
        <h4 className="uppercase text-[20px] font-semibold">
          {strings.topCities}
        </h4>
        {
          citiesLoading ? <ClipLoader size={18} /> : (
            <div className="row justify-content-between mt-4">
              {adsByCity.slice(0, 5).map((cityData, index) => {
                return (
                  <div className="col-md text-center cursor-pointer" key={index}
                    onClick={() => setSelectedCity(cityData?.city)}
                  >
                    <div className="position-relative d-flex align-items-center justify-start">
                      <img
                        className="md:w-[300px] md:h-[300px] w-[100%] md:mt-0 mt-2 opacity-90"
                        src={cityData.imageUrl}
                        alt=""
                        style={{ position: "relative", zIndex: "1" }}
                      />
                      <div
                        className="position-absolute bottom-6 text-lg font-medium tracking-wide center-0 p-3 text-white "
                        style={{ zIndex: "2" }}
                      >
                        {cityData?.city.slice(0, 14)}
                      </div>
                      <div
                        className="position-absolute bottom-1 text-sm center-0 p-3 text-white opacity-80"
                        style={{ zIndex: "2", fontWeight: "300" }}
                      >
                        {`${cityData?.adds} Ads`}
                      </div>

                      <div
                        className="position-absolute inset-0 opacity-40"
                        style={{ zIndex: "1" }}
                      ></div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        }


      </div>
      {
        selectedCity !== '' && (
          <div className="container">
            <h3>{selectedCity} Ads</h3>
            <div className="grid grid-cols-1  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-3 justify-center">
              {selectedCityData.map((item, index) => (
                <div
                  className="c shadow-xl rounded-xl  cursor-pointer "

                  onClick={() => {
                    navigate(`${item.section === '638f25154cb7c29ec6ad4bfe' ? `/real-estate-details/${item._id}` : `/vehicle-details/${item._id}`}`, {
                      state: {
                        details: item,
                      },
                    });
                  }}

                >
                  <div className="relative h-40">
                    {!item?.fav.includes(userId) ? (
                      <FavoriteBorderOutlinedIcon
                        className="absolute items-content-end w-12"
                        style={{
                          right: "8px",
                          color: "#F37521",
                          backgroundColor: "white",
                          borderRadius: 100,
                          padding: 3,
                          top: 8,
                        }}
                        onClick={(event) => {
                          if (user) {
                            addToFavorites(event, item._id);
                          }
                        }}
                      />
                    ) : (
                      <FavoriteOutlinedIcon
                        className="absolute items-content-end w-12 opacity-100"
                        style={{
                          right: "8px",
                          color: "red",
                          backgroundColor: "white",
                          borderRadius: 100,
                          padding: 3,
                          top: 8,
                        }}
                        onClick={(event) => {
                          if (user) {
                            addToFavorites(event, item._id);
                          }
                        }}
                      />
                    )}
                    <img
                      className=" rounded-t-2xl"
                      src={item?.coverPhoto}
                      alt="random"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        // borderTopRightRadius: 12,
                        // borderTopLeftRadius: 12,
                      }}
                    />
                  </div>
                  <div className="px-[10px] mt-2">
                    <div
                      className="flex items-center justify-between"
                    
                    >
                      <div>
                        <h3 className="font-[500] text-sm ">
                          {item?.basicInformation?.title
                            .slice(0, 12)
                            .split(" ")
                            .slice(0, 5)
                            .join(" ")}
                        </h3>
                      </div>

                      <div className="flex " style={{ color: "#F37521" }}>
                        <h1 className="font-bold text-sm ">
                          {item?.basicInformation?.price?.currency}&nbsp;
                        </h1>
                        <h1 className="font-bold text-sm ">
                          {item?.basicInformation?.price?.price}
                        </h1>
                      </div>
                    </div>
                    <div className="flex gap-2 -mb-2">
                      <h1 className="text-[9px] font-[350] text-gray-400 ">
                        {item?.basicInformation?.description?.slice(0, 25)}
                      </h1>
                    </div>
                    <div
                      className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"
                    
                    >
                      <div className="flex gap-2 items-center">
                        <img src={year} alt="year" className="h-3 w-3" />
                        <p className=" mt-[17px]">
                          {item?.basicInformation?.details[0]?.value}
                        </p>
                      </div>

                      <div className="flex gap-2 items-center">
                        <img
                          src={engineSize}
                          alt="year"
                          className="h-3 w-3"
                        />
                        <p className=" mt-[17px]">
                          {item?.basicInformation?.details
                            .filter((item) =>
                              [
                                "Engine Size",
                                "Размер двигателя",
                                "Dvigatel hajmi",
                              ].includes(item?.fieldName)
                            )
                            .map((filteredItem, index) => (
                              <span key={index}>{filteredItem?.value}</span>
                            ))}
                        </p>
                      </div>

                      <div className="flex gap-2 items-center">
                        <img
                          src={calendar}
                          color="gray"
                          alt="year"
                          className="h-3 w-3 opacity-60"
                        />
                        <p className=" mt-[17px]">
                          {item?.basicInformation?.KM}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }


      <div className="container ">
        <div className="shadow-md bg-white rounded-[10px] ">
          <div className="flex justify-content-center pt-[20px] underline ">

            {strings.whatDo}
          </div>

          <div className="row section">
            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11921} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findPlace}
              </h6>
              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.findPlaceText.slice(0, 80)}
              </p>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11922} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.findVehicle}
              </h6>

              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.findVehicleText.slice(0, 80)}
              </p>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11923} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.buyRent}
              </h6>
              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.buyRentText.slice(0, 80)}
              </p>
            </div>

            <div className="col-12 flex flex-col items-center col-md-6 col-lg-3 ">
              <img src={Group11924} alt="" />
              <h6
                className="text-center mt-4 fw-bold"
                style={{ fontSize: "12px" }}
              >
                {strings.listAdd}
              </h6>
              <p
                style={{
                  fontSize: "10px",
                  opacity: "0.6",
                  textAlign: "center",
                }}
              >
                {strings.listAddText.slice(0, 80)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*............ */}

      <div className="container">
        <div className="contain     bg-white shadow-md rounded-lg ">
          <div className="flex flex-col md:flex-row ">
            <div className="w-80 p-3 pt-5">
              <h6 className="w-64 fs-3 text-orange-400">{strings.getApp}</h6>
              <p style={{ fontSize: "14px" }}>{strings.getAppP}</p>
            </div>
            <div className="mt-3 ">
              <img className="w-52 h-52" src={mobile} alt="" />
            </div>

            <div className=" grid grid-cols-1 gap-2 px-3 py-4 md:grid-cols-1 lg:grid-cols-3 ms-auto">
              <div>
                <img
                  className="w-40 h-12 cursor-pointer"
                  src={appstore}
                  alt=""
                />
              </div>
              <div>
                <img
                  className="w-40 h-12 cursor-pointer "
                  src={googleplay}
                  alt=""
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Partners ..............7....... */}
      <div className="container ">
        <h5 className="p-0 m-0 font-semibold text-[20px]">{strings.ourP}</h5>
        <div className="grid grid-cols-2 lg:grid-cols-6 mt-4 md:grid-cols-3 md:gap-3 lg:gap-4 justify-items-center">
          {
            partners?.map((item, index) => (
              <div className="rounded   max-w-sm mt-4 flex items-center" key={index}>
                <img className="w-32 " src={item.imageUrl} alt="" />
              </div>
            ))
          }


        </div>
      </div>

      {/* Footer */}
      <Footer strings={strings} />
    </div>
  );
};

export default Body;
