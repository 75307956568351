import apiClient from "../utils/apiClient"

export const getApi = async (endPoint) => {
    try {
        const response = await apiClient.get(`${endPoint}`)

        return response
    } catch (error) {
        return error
    }
}