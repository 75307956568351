import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import facebook from "../../images/facebook.svg";
import Google from "../../images/google.svg";
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instagram.svg";
import paragraph from "../../images/paragraph.svg";
import phone from "../../images/phone.svg";
import sms from "../../images/sms.svg";
import { setLanguage } from "../../app/languageSlice";
import { useDispatch } from "react-redux";

import strings from "../../utils/locals/languages";
import Cookies from "js-cookie";

import apiClient from "../../utils/apiClient";
import { ClipLoader } from "react-spinners";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";

const Favorites = () => {
  const [data, setData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [section, setSection] = useState("638f25154cb7c29ec6ad4bfe");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [menu1Open, setMenu1Open] = useState(false);
  const button1Ref = React.useRef(null);
  const button2Ref = React.useRef(null);
  const [reLoad, setReLoad] = useState(0);

  // event handlers for opening and closing each menu
  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (language) => {
    strings.setLanguage(language); // set the language for the LocalizedStrings instance

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language)); // dispatch an action to update the language in the store
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language");

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage);
    }
  }, []);

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    window.location.reload();
  };

  const addToFavorites = (event, adId) => {
    event.stopPropagation();

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        setReLoad(reLoad + 1);
      })
      .catch((err) => {});
  };
  const getNotifications = async() => {
    try {
     const response = await apiClient.get("/v1/user/notification")
     setNotificationCount(response.data.length);
     setNotificationData(response.data);
    } catch (err) {
 
    }
 
   }
 
   useEffect(() => {
     getNotifications()
   }, []);

  useEffect(() => {
    setIsLoading(true);
    apiClient
      .get(`/v1/user/favorite?section=${section}`)
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
      });
  }, [section, reLoad]);

  

  return (
    <>
      <div className="container mx-auto">
        <h3 className="text-center font-bold text-xl uppercase">
          {strings.fav}
        </h3>
      </div>
      <div className="container">
        <div className="container flex justify-end">
          <div className="custom  mt-3 w-40 h-[40px] bg-transparent pt-[6px] itemce rounded-full  border-1 border-black bor  bg-white text-center">
            <select
              className="p-1 text-sm font-semibold outline-none bg-transparent tracking-wide"
              // className="custom-select mt-3 w-40 h-14 rounded-full  border-1 border-black bor  bg-white  px-2"
              onChange={(e) => {
                setSection(e.target.value);
              }}
              value={section}
            >
              <option value="638f25154cb7c29ec6ad4bfe">
                {strings.realEstates}
              </option>
              <option value="638f1fd34cb7c29ec6ad4bd6">
                {strings.vehicles}
              </option>
            </select>
          </div>
        </div>
      </div>
      {/*Recmonded*/}
      <div className="container mx-auto mt-4">
        {isLoading ? (
          <div className="container flex justify-center">
            <div>
              <ClipLoader
                loading={isLoading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : (
          <div className="container ">
            <div className="">
              {data?.length === 0 ? (
                <div className="flex justify-center">
                  <h3>{strings.norec}</h3>
                </div>
              ) : (
                <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4  xl:grid-cols-4 gap-5 justify-center">
                  {data?.map((item, index) => (
                    <div
                      className="ca rounded-xl shadow-md border-none cursor-pointer -mb-5 w "
                      onClick={() => {
                        navigate(
                          `${
                            section === "638f25154cb7c29ec6ad4bfe"
                              ? "/real-estate-details/"
                              : "/vehicle-details/"
                          }${item._id}`,
                          {
                            state: {
                              details: item,
                            },
                          }
                        );
                      }}
                    >
                      <div className="relative h-40" style={{}}>
                        <FavoriteOutlinedIcon
                          className="absolute items-content-end w-12"
                          style={{
                            right: "8px",
                            color: "red",
                            backgroundColor: "white",
                            borderRadius: 100,
                            padding: 3,
                            top: 8,
                          }}
                          onClick={(event) => addToFavorites(event, item._id)}
                        />
                        <img
                          className=" rounded-t-2xl"
                          src={item.imageUrl[0]}
                          alt="random"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        className="px-[10px] mt"
                        style={{ overflow: "hidden" }}
                      >
                        <div
                          className="flex items-center justify-between mt-1"
                          // style={{ height: "30%" }}
                        >
                          <div>
                            <h3 className="font-[500] text-[13px] ">
                              {item.basicInformation.title
                                .slice(0, 15)
                                .split(" ")
                                .slice(0, 5)
                                .join(" ")}
                            </h3>
                          </div>

                          <div className="flex " style={{ color: "#F37521" }}>
                            <h1 className="font-bold text-sm ">
                              {item.basicInformation.price.currency}
                            </h1>
                            <h1 className="font-bold text-sm ">
                              {item.basicInformation.price.price}
                            </h1>
                          </div>
                        </div>
                        <div className="flex gap-2 -mb-[15px]">
                          <h1 className="text-[9px] font-[350] text-gray-500 ">
                            {item.location.country}
                          </h1>
                          <h1 className="text-[9px] font-[350] text-gray-500 ">
                            {item.location.city}
                          </h1>
                        </div>
                        <div
                          className="flex justify-between items-center -mb-2"
                          // style={{ height: "40%" }}
                        >
                          <div className="flex gap-1 items-center">
                            <p className="text-xs pt-3">For {item.purpose}</p>
                          </div>

                          <div className="flex gap-1 items-center">
                            <p className="text-xs pt-3">
                              KM {item.basicInformation.KM}{" "}
                            </p>
                          </div>

                          {/* <div className="flex gap-1 items-center">
                        <img className="h-4" src={comment} alt="" />
                        <p className="text-sm pt-3">3</p>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>


      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">
            {strings.notif}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          {notificationData.map((data, index) => (
            <div
              key={index}
              className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""}`}
            >
              <p style={{ fontWeight: "bold" }}>
                {index + 1}. {data.title}
              </p>
              <p>{data.message}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Favorites;
