import { englishDetails, russianDetails, uzbekDetails } from '../translation-files/vehicle';

export const translateDetails = async (data, source, target) => {
    try {
        if (source === "en") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = englishDetails.find(detail => detail.fieldId === dataItem.fieldName);
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        dropDown: matchedDetail.dropDown,
                        fieldId: matchedDetail.fieldId,
                        value: dataItem.value
                    };


                    return updatedData;
                }

                return dataItem;
            });


            const russianData = []

            updatedFeatures.map((item) => {
                const matchedData = russianDetails.find(detail => detail.fieldId === item.fieldId);
                if (matchedData) {

                    const dropDown = matchedData.dropDown.find(data => data.fieldId === item.value);

                    if (dropDown) {
                        const updatedRussianDetails = {
                            fieldId: matchedData.fieldId,
                            fieldName: matchedData.fieldName,
                            value: dropDown.fieldValue,
                            imageUrl: item.imageUrl
                        };
                        russianData.push(updatedRussianDetails)
                    }
                }
            })

            const uzbekData = []
            updatedFeatures.map((item) => {
                const matchedData = uzbekDetails.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {

                    const dropDown = matchedData.dropDown.find(data => data.fieldId === item.value);

                    if (dropDown) {
                        const updatedUzbekDetails = {
                            fieldId: matchedData.fieldId,
                            fieldName: matchedData.fieldName,
                            value: dropDown.fieldValue,
                            imageUrl: item.imageUrl
                        };
                        uzbekData.push(updatedUzbekDetails)
                    }
                }
            })

            return {
                english: updatedFeatures,
                russian: russianData,
                uzbek: uzbekData
            };
        } else if (source === "ru") {

            const updatedFeatures = data.map(dataItem => {

                const matchedDetail = russianDetails.find(detail => detail.fieldName === dataItem.fieldName);


                if (matchedDetail) {
                    const dropDown = matchedDetail.dropDown.find(data => data.fieldValue === dataItem.value);

                    if (dropDown) {
                        const updatedData = {

                            ...dataItem,
                            dropDown: matchedDetail.dropDown,
                            fieldId: matchedDetail.fieldId,
                            value: dataItem.value,
                            dropDownFieldId: dropDown.fieldId
                        };
                        return updatedData;

                    }
                }

                return dataItem;
            });

            const englishData = [];

            updatedFeatures.forEach((item) => {
                const matchedData = englishDetails.find(detail => detail.fieldId === item.fieldId);


                if (matchedData) {
                    const dropDown = matchedData.dropDown.find(data => data.fieldId === item.dropDownFieldId);

                    if (dropDown) {
                        const englishDetailsItem = {
                            fieldId: matchedData.fieldId,
                            fieldName: matchedData.fieldName,
                            value: dropDown.fieldValue,
                            imageUrl: item.imageUrl
                        };
                        englishData.push(englishDetailsItem);
                    }
                }
            });

            const uzbekData = []

            updatedFeatures.forEach((item) => {
                const matchedData = uzbekDetails.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const dropDown = matchedData.dropDown.find(data => data.fieldId === item.dropDownFieldId);

                    if (dropDown) {
                        const uzbekDetails = {
                            fieldId: matchedData.fieldId,
                            fieldName: matchedData.fieldName,
                            value: dropDown.fieldValue,
                            imageUrl: item.imageUrl
                        };
                        uzbekData.push(uzbekDetails);
                    }
                }
            });

            return {
                english: englishData,
                russian: updatedFeatures,
                uzbek: uzbekData
            };
        } else if (source === "uz") {
            const updatedFeatures = data.map(dataItem => {

                const matchedDetail = uzbekDetails.find(detail => detail.fieldName === dataItem.fieldName);


                if (matchedDetail) {
                    const dropDown = matchedDetail.dropDown.find(data => data.fieldValue === dataItem.value);

                   if (dropDown) {
                    const updatedData = {

                        ...dataItem,
                        dropDown: matchedDetail.dropDown,
                        fieldId: matchedDetail.fieldId,
                        value: dataItem.value,
                        dropDownFieldId: dropDown.fieldId
                    };

                    return updatedData;
                   }
                }

                return dataItem;
            });

            const englishData = [];

            updatedFeatures.forEach((item) => {
                const matchedData = englishDetails.find(detail => detail.fieldId === item.fieldId);


                if (matchedData) {
                    const dropDown = matchedData.dropDown.find(data => data.fieldId === item.dropDownFieldId);

                    if (dropDown) {
                        const englishDetailsItem = {
                            fieldId: matchedData.fieldId,
                            fieldName: matchedData.fieldName,
                            value: dropDown.fieldValue,
                            imageUrl: item.imageUrl
                        };
                        englishData.push(englishDetailsItem);
                    }
                }
            });

            const russianData = []

            updatedFeatures.forEach((item) => {
                const matchedData = russianDetails.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const dropDown = matchedData.dropDown.find(data => data.fieldId === item.dropDownFieldId);

                    if (dropDown) {
                        const russianDetails = {
                            fieldId: matchedData.fieldId,
                            fieldName: matchedData.fieldName,
                            value: dropDown.fieldValue,
                            imageUrl: item.imageUrl
                        };
                        russianData.push(russianDetails);
                    }
                }
            });

            return {
                english: englishData,
                russian: russianData,
                uzbek: updatedFeatures
            };
        }
    } catch (error) {
        return [];
    }

}