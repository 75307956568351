import React, { useState, useEffect } from 'react'
import Body from './Body'
import AppBar from "../../components/AppBar";
import { useParams } from "react-router-dom";
import apiClient from '../../utils/apiClient';
import strings from '../../utils/locals/languages';
import { ClipLoader } from "react-spinners";
import { vehicleId } from '../../utils/baseUrl';
import Cookies from "js-cookie";


const HondaCarForSale = () => {
  const { id } = useParams();

  const [innerdetails, setInnerDetails] = useState()
  const [isDataLoading, setIsDataLoading] = useState(true)

  const selectedLanguage = Cookies.get("language");

  useEffect(() => {
  
    apiClient
      .get(
        `/v1/adds/fetch-ad-details/${id}?language=${selectedLanguage === undefined ? "en" : selectedLanguage}&section=${vehicleId}`
      )
      .then((response) => {
        setInnerDetails(response.data);
        setIsDataLoading(false)

      })
      .catch((err) => { });;
  }, [strings._language, id])

  return (
    <div>
      {
        isDataLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Adjust this value to fit your page height
            }}
          >
            <ClipLoader />
          </div>
        ) : (
          <>
            <AppBar id={id} createPage='/create-vehicle-ad' disableLanguage={false} />
            <Body innerdetails={innerdetails} />
          </>
        )
      }
    </div>
  )
}

export default HondaCarForSale;
