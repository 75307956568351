import { englishExternalFeatures, russianExternalFeatures, uzbekExternalFeatures } from "../translation-files/vehicle";

export const translateExternalFeatures = async (data, source, target) => {
    try {
        if (source === "en") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = englishExternalFeatures.find(detail => detail.fieldId === dataItem.fieldName);
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };

                    return updatedData;
                }
                return dataItem

            });

            const russianData = []
            updatedFeatures.map((item) => {
                const matchedData = russianExternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };

                    russianData.push(updatedData)
                } else {
                    const manualDetails = {
                        fieldName: item.fieldName,
                    };
                    russianData.push(manualDetails)
                }
            })

            const uzbekData = []
            updatedFeatures.map((item) => {
                const matchedData = uzbekExternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };

                    uzbekData.push(updatedData)
                } else {
                    const manualDetails = {
                        fieldName: item.fieldName,
                    };
                    uzbekData.push(manualDetails)
                }
            })

            return {
                englishFeatures: updatedFeatures,
                russianFeatures: russianData,
                uzbekFeatures: uzbekData
            };
        } else if (source === "ru") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = russianExternalFeatures.find(detail => detail.fieldName === dataItem.fieldName);
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };

                    return updatedData;
                }

                return dataItem;
            });

            const englishData = []
            updatedFeatures.map((item) => {
                const matchedData = englishExternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };

                    englishData.push(updatedData)
                } else {
                    const manualDetails = {
                        fieldName: item.fieldName,
                    };
                    englishData.push(manualDetails)
                }
            })

            const uzbekData = []
            updatedFeatures.map((item) => {
                const matchedData = uzbekExternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };

                    uzbekData.push(updatedData)
                } else {
                    const manualDetails = {
                        fieldName: item.fieldName,
                    };
                    uzbekData.push(manualDetails)
                }
            })

            return {
                englishFeatures: englishData,
                russianFeatures: updatedFeatures,
                uzbekFeatures: uzbekData
            };
        } else if (source === "uz") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = uzbekExternalFeatures.find(detail => detail.fieldName === dataItem.fieldName);
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };

                    return updatedData;
                }

                return dataItem;
            });

            const englishData = []
            updatedFeatures.map((item) => {
                const matchedData = englishExternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };

                    englishData.push(updatedData)
                }
            })

            const russianData = []
            updatedFeatures.map((item) => {
                const matchedData = russianExternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                        value: matchedData.value
                    };

                    russianData.push(updatedData)
                } else {
                    const manualDetails = {
                        fieldName: item.fieldName,
                    };
                    russianData.push(manualDetails)
                }
            })

            return {
                englishFeatures: englishData,
                russianFeatures: russianData,
                uzbekFeatures: updatedFeatures
            };
        }
    } catch (error) {

        return [];
    }

}