import { englishBodyParts, russianBodyParts, uzbekBodyParts } from "../translation-files/vehicle";

export const translateBodyParts = async (data, source, target) => {
    try {
        const updatedFeatures = data.map(dataItem => {
            const matchedDetail = englishBodyParts.find(detail => detail.fieldId === dataItem.fieldName);
            if (matchedDetail) {
                const updatedData = {
                    ...dataItem,
                    fieldId: matchedDetail.fieldId,
                };

                return updatedData;
            }

            return dataItem;
        });


        const russianData = []
        updatedFeatures.map((item) => {
            const matchedData = russianBodyParts.find(detail => detail.fieldId === item.fieldId);

            if (matchedData) {
                const updatedData = {
                    fieldId: matchedData.fieldId,
                    fieldName: matchedData.fieldName,
                    value: item.value === "original" ? "оригинальный" : item.value === "painted" ? "окрашенный" : "заменены",
                };

                russianData.push(updatedData)
            }
        })

        const uzbekData = []
        updatedFeatures.map((item) => {
            const matchedData = uzbekBodyParts.find(detail => detail.fieldId === item.fieldId);

            if (matchedData) {
                const updatedData = {
                    fieldId: matchedData.fieldId,
                    fieldName: matchedData.fieldName,
                    value: item.value === "original" ? "original" : item.value === "painted" ? "bo'yalgan" : "almashtirildi",
                };

                uzbekData.push(updatedData)
            }
        })

        return {
            english: updatedFeatures,
            russian: russianData,
            uzbek: uzbekData
        };

    } catch (error) {
        return []; // Return an empty array in case of error
    }

}