export const englishDetails = [
    {
        fieldName: "Year",
        fieldId: "Year",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1703782049624_g6vo6cp9_image.png",
        dropDown: [
            { fieldId: "2024", fieldValue: "2024" },
            { fieldId: "2023", fieldValue: "2023" },
            { fieldId: "2022", fieldValue: "2022" },
            { fieldId: "2021", fieldValue: "2021" },
            { fieldId: "2020", fieldValue: "2020" },
            { fieldId: "2019", fieldValue: "2019" },
            { fieldId: "2018", fieldValue: "2018" },
            { fieldId: "2017", fieldValue: "2017" },
            { fieldId: "2016", fieldValue: "2016" },
            { fieldId: "2015", fieldValue: "2015" },
            { fieldId: "2014", fieldValue: "2014" },
            { fieldId: "2013", fieldValue: "2013" },
            { fieldId: "2012", fieldValue: "2012" },
            { fieldId: "2011", fieldValue: "2011" },
            { fieldId: "2010", fieldValue: "2010" },
            { fieldId: "2009", fieldValue: "2009" },
            { fieldId: "2008", fieldValue: "2008" },
            { fieldId: "2007", fieldValue: "2007" },
            { fieldId: "2006", fieldValue: "2006" },
            { fieldId: "2005", fieldValue: "2005" },
            { fieldId: "2004", fieldValue: "2004" },
            { fieldId: "2003", fieldValue: "2003" },
            { fieldId: "2002", fieldValue: "2002" },
            { fieldId: "2001", fieldValue: "2001" },
            { fieldId: "2000", fieldValue: "2000" },
            { fieldId: "1999", fieldValue: "1999" },
            { fieldId: "1998", fieldValue: "1998" },
            { fieldId: "1997", fieldValue: "1997" },
            { fieldId: "1996", fieldValue: "1996" },
            { fieldId: "1995", fieldValue: "1995" },
            { fieldId: "1994", fieldValue: "1994" },
            { fieldId: "1993", fieldValue: "1993" },
            { fieldId: "1992", fieldValue: "1992" },
            { fieldId: "1991", fieldValue: "1991" },
            { fieldId: "1990", fieldValue: "1990" },
            { fieldId: "1980-1989", fieldValue: "1980-1989" },
            { fieldId: "1970-1979", fieldValue: "1970-1979" },
            { fieldId: "1060-1969", fieldValue: "1060-1969" },
            { fieldId: "1950-1959", fieldValue: "1950-1959" }
        ],
        _id: "641c0c2e690ed315f0e1f7d4"
    },
    {
        fieldName: "Transmission Type",
        fieldId: "Transmission Type",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1688711153648",
        dropDown: [
            { fieldId: "Automatic Transmission (AT)", fieldValue: "Automatic Transmission (AT)" },
            { fieldId: "Manual Transmission (MT)", fieldValue: "Manual Transmission (MT)" },
            { fieldId: "Automated Manual Transmission (AMT)", fieldValue: "Automated Manual Transmission (AMT)" },
            { fieldId: "Continuously Variable Transmission (CVT)", fieldValue: "Continuously Variable Transmission (CVT)" },
        ],
        _id: "641c0c2e690ed315f0e1f7d6"
    },
    {
        fieldName: "Engine Size",
        fieldId: "Engine Size",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559119224",
        dropDown: [
            { fieldId: "50cc", fieldValue: "50cc" },
            { fieldId: "75cc", fieldValue: "75cc" },
            { fieldId: "90cc", fieldValue: "90cc" },
            { fieldId: "100cc", fieldValue: "100cc" },
            { fieldId: "125cc", fieldValue: "125cc" },
            { fieldId: "150cc", fieldValue: "150cc" },
            { fieldId: "200cc", fieldValue: "200cc" },
            { fieldId: "300cc", fieldValue: "300cc" },
            { fieldId: "400cc", fieldValue: "400cc" },
            { fieldId: "500cc", fieldValue: "500cc" },
            { fieldId: "600cc", fieldValue: "600cc" },
            { fieldId: "700cc", fieldValue: "700cc" },
            { fieldId: "800cc", fieldValue: "800cc" },
            { fieldId: "1000cc", fieldValue: "1000cc" },
            { fieldId: "1200cc", fieldValue: "1200cc" },
            { fieldId: "1400cc", fieldValue: "1400cc" },
            { fieldId: "1600cc", fieldValue: "1600cc" },
            { fieldId: "1800cc", fieldValue: "1800cc" },
            { fieldId: "2000cc", fieldValue: "2000cc" },
            { fieldId: "2200cc", fieldValue: "2200cc" },
            { fieldId: "2400cc", fieldValue: "2400cc" },
            { fieldId: "2400cc", fieldValue: "2400cc" },
            { fieldId: "2600cc", fieldValue: "2600cc" },
            { fieldId: "2800cc", fieldValue: "2800cc" },
            { fieldId: "3000cc", fieldValue: "3000cc" }
        ],
        _id: "641c0c2e690ed315f0e1f7d7"
    },
    {
        fieldName: "Wheel Drive",
        fieldId: "Wheel Drive",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559168263",
        dropDown: [
            { fieldId: "2WD", fieldValue: "2WD" },
            { fieldId: "FWD", fieldValue: "FWD" },
            { fieldId: "RWD", fieldValue: "RWD" },
            { fieldId: "AWD", fieldValue: "AWD" },
            { fieldId: "4WD", fieldValue: "4WD" }
        ],
        _id: "641c0c2e690ed315f0e1f7d8"
    },
    {
        fieldName: "Fuel Type",
        fieldId: "Fuel Type",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559200775",
        dropDown: [
            { fieldId: "Petrol", fieldValue: "Petrol" },
            { fieldId: "Methanol", fieldValue: "Methanol" },
            { fieldId: "Gasoline", fieldValue: "Gasoline" },
            { fieldId: "Diesel", fieldValue: "Diesel" },
            { fieldId: "Propane", fieldValue: "Propane" },
            { fieldId: "Natural Gas", fieldValue: "Natural Gas" },
            { fieldId: "Hydrogen", fieldValue: "Hydrogen" },
            { fieldId: "cng", fieldValue: "cng" },
            { fieldId: "Electric", fieldValue: "Electric" }
        ],
        _id: "641c0c2e690ed315f0e1f7d9"
    },
    {
        fieldName: "Currency",
        fieldId: "Currency",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688562334438",
        dropDown: [
            { fieldId: "$", fieldValue: "$" },
            { fieldId: "€", fieldValue: "€" },
            { fieldId: "UZS", fieldValue: "UZS" }
        ],
        _id: "641c0c2e690ed315f0e1f7db"
    },
    {
        fieldName: "Color",
        fieldId: "Color",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559372316",
        dropDown: [
            { fieldId: "White", fieldValue: "White" },
            { fieldId: "Black", fieldValue: "Black" },
            { fieldId: "Red", fieldValue: "Red" },
            { fieldId: "Gray", fieldValue: "Gray" },
            { fieldId: "Silver", fieldValue: "Silver" },
            { fieldId: "Blue", fieldValue: "Blue" },
            { fieldId: "Brown", fieldValue: "Brown" },
            { fieldId: "Green", fieldValue: "Green" },
            { fieldId: "Orange", fieldValue: "Orange" },
            { fieldId: "Beige", fieldValue: "Beige" },
            { fieldId: "Purple", fieldValue: "Purple" },
            { fieldId: "Gold", fieldValue: "Gold" },
            { fieldId: "Yellow", fieldValue: "Yellow" }
        ],
        _id: "641c0c2e690ed315f0e1f7dd"
    },
    {
        fieldName: "Warranty",
        fieldId: "Warranty",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690191874408",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f7de"
    },
    {
        fieldName: "From",
        fieldId: "From",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559453492",
        dropDown: [
            { fieldId: "Owner", fieldValue: "Owner" },
            { fieldId: "Dealer", fieldValue: "Dealer" }
        ],
        _id: "641c0c2e690ed315f0e1f7df"
    },
    {
        fieldName: "Condition",
        fieldId: "Condition",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690192152428",
        dropDown: [
            { fieldId: "Used", fieldValue: "Used" },
            { fieldId: "New", fieldValue: "New" }
        ],
        _id: "641c0c2e690ed315f0e1f7e0"
    },
    {
        fieldName: "Barter/Exchange",
        fieldId: "Barter/Exchange",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559514220",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f7e1"
    },
    {
        fieldName: "Plate ID",
        fieldId: "Plate ID",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559546011",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f7e2"
    },
    {
        fieldName: "Purpose",
        fieldId: "Purpose",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559265731",
        dropDown: [
            { fieldId: "Sale", fieldValue: "Sale" },
            { fieldId: "Rent", fieldValue: "Rent" },
            { fieldId: "Daily Rent", fieldValue: "Kunlik ijara" }
        ],
        _id: "641c0c2e690ed315f0e1f89c"
    },
    {
        fieldName: "Video Call",
        fieldId: "Video Call",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1704736201576_e10rteew_image.png",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f7e3"
    }
];


export const englishInternalFeatures = [
    {
        fieldName: "Leather Seat",
        fieldId: "Leather Seat",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1688551959801",
        _id: "641c0c2e690ed315f0e1f7e5"
    },
    {
        fieldName: "7 Speed Gear",
        fieldId: "7 Speed Gear",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552058676",
        _id: "641c0c2e690ed315f0e1f7e6"
    },
    {
        fieldName: "Leather Steering Wheel",
        fieldId: "Leather Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552107761",
        _id: "641c0c2e690ed315f0e1f7e7"
    },
    {
        fieldName: "Seats Memory",
        fieldId: "Seats Memory",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552194379",
        _id: "641c0c2e690ed315f0e1f7e8"
    },
    {
        fieldName: "Seats Cooler",
        fieldId: "Seats Cooler",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552350612",
        _id: "641c0c2e690ed315f0e1f7e9"
    },
    {
        fieldName: "Seat Heater",
        fieldId: "Seat Heater",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552420497",
        _id: "641c0c2e690ed315f0e1f7ea"
    },
    {
        fieldName: "Trip Computer",
        fieldId: "Trip Computer",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1696583350833",
        _id: "641c0c2e690ed315f0e1f7eb"
    },
    {
        fieldName: "Start/Stop button",
        fieldId: "Start/Stop button",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552549763",
        _id: "641c0c2e690ed315f0e1f7ec"
    },
    {
        fieldName: "Fabric Seat",
        fieldId: "Fabric Seat",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552604309",
        _id: "641c0c2e690ed315f0e1f7ed"
    },
    {
        fieldName: "Analogue Air Conditioner",
        fieldId: "Analogue Air Conditioner",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552677230",
        _id: "641c0c2e690ed315f0e1f7ee"
    },
    {
        fieldName: "Cruise control",
        fieldId: "Cruise control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552732999",
        _id: "641c0c2e690ed315f0e1f7ef"
    },
    {
        fieldName: "Rear View Camera",
        fieldId: "Rear View Camera",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552787914",
        _id: "641c0c2e690ed315f0e1f7f0"
    },
    {
        fieldName: "Digital Air Conditioner",
        fieldId: "Digital Air Conditioner",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552826325",
        _id: "641c0c2e690ed315f0e1f7f1"
    },
    {
        fieldName: "Heated Steering Wheel",
        fieldId: "Heated Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552911924",
        _id: "641c0c2e690ed315f0e1f7f2"
    },
    {
        fieldName: "Adaptive Cruise Control",
        fieldId: "Adaptive Cruise Control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552957415",
        _id: "641c0c2e690ed315f0e1f7f3"
    },
    {
        fieldName: "Front View Camera",
        fieldId: "Front View Camera",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552994949",
        _id: "641c0c2e690ed315f0e1f7f4"
    },
    {
        fieldName: "Auto-Darkening Rear View",
        fieldId: "Auto-Darkening Rear View",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553046653",
        _id: "641c0c2e690ed315f0e1f7f5"
    },
    {
        fieldName: "Adjustable Steering Wheel",
        fieldId: "Adjustable Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553080182",
        _id: "641c0c2e690ed315f0e1f7f6"
    },
    {
        fieldName: "Refrigerator",
        fieldId: "Refrigerator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553117182",
        _id: "641c0c2e690ed315f0e1f7f7"
    },
    {
        fieldName: "Radio",
        fieldId: "Radio",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553189272",
        _id: "641c0c2e690ed315f0e1f7f8"
    },
    {
        fieldName: "Cassette Player",
        fieldId: "Cassette Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553220981",
        _id: "641c0c2e690ed315f0e1f7f9"
    },
    {
        fieldName: "DVD/CD Player",
        fieldId: "DVD/CD Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553365205",
        _id: "641c0c2e690ed315f0e1f7fa"
    },
    {
        fieldName: "DVD Changer",
        fieldId: "DVD Changer",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553411336",
        _id: "641c0c2e690ed315f0e1f7fb"
    },
    {
        fieldName: "Bluetooth",
        fieldId: "Bluetooth",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553447407",
        _id: "641c0c2e690ed315f0e1f7fc"
    },
    {
        fieldName: "iPod Connection",
        fieldId: "iPod Connection",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553495010",
        _id: "641c0c2e690ed315f0e1f7fd"
    },
    {
        fieldName: "MP3 Player",
        fieldId: "MP3 Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553536258",
        _id: "641c0c2e690ed315f0e1f7fe"
    },
    {
        fieldName: "Navigation",
        fieldId: "Navigation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553636593",
        _id: "641c0c2e690ed315f0e1f7ff"
    },
    {
        fieldName: "USB/AUX",
        fieldId: "USB/AUX",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553740372",
        _id: "641c0c2e690ed315f0e1f800"
    }
]

export const englishExternalFeatures = [
    {
        fieldName: "Bi Xenon Headlight",
        fieldId: "Bi Xenon Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553863236",
        _id: "641c0c2e690ed315f0e1f801"
    },
    {
        fieldName: "Headlight Washing",
        fieldId: "Headlight Washing",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553917397",
        _id: "641c0c2e690ed315f0e1f802"
    },
    {
        fieldName: "Alloy Wheel",
        fieldId: "Alloy Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553957860",
        _id: "641c0c2e690ed315f0e1f803"
    },
    {
        fieldName: "Rear Window Defroster",
        fieldId: "Rear Window Defroster",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554034414",
        _id: "641c0c2e690ed315f0e1f804"
    },
    {
        fieldName: "LED Headlight",
        fieldId: "LED Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554070642",
        _id: "641c0c2e690ed315f0e1f805"
    },
    {
        fieldName: "Fog light",
        fieldId: "Fog light",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554136155",
        _id: "641c0c2e690ed315f0e1f806"
    },
    {
        fieldName: "Electronic Mirrors",
        fieldId: "Electronic Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554181983",
        _id: "641c0c2e690ed315f0e1f807"
    },
    {
        fieldName: "Back Parking Sensor",
        fieldId: "Back Parking Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554258581",
        _id: "641c0c2e690ed315f0e1f808"
    },
    {
        fieldName: "Front Parking Sensor",
        fieldId: "Front Parking Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554307958",
        _id: "641c0c2e690ed315f0e1f809"
    },
    {
        fieldName: "Sunroof",
        fieldId: "Sunroof",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555206549",
        _id: "641c0c2e690ed315f0e1f80a"
    },
    {
        fieldName: "Halogen Headlight",
        fieldId: "Halogen Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555240192",
        _id: "641c0c2e690ed315f0e1f80b"
    },
    {
        fieldName: "Adaptive Headlight",
        fieldId: "Adaptive Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555343733",
        _id: "641c0c2e690ed315f0e1f80c"
    },
    {
        fieldName: "Auto-Folding Mirrors",
        fieldId: "Auto-Folding Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555464212",
        _id: "641c0c2e690ed315f0e1f80d"
    },
    {
        fieldName: "Panoramic Glass Ceiling",
        fieldId: "Panoramic Glass Ceiling",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555503342",
        _id: "641c0c2e690ed315f0e1f80e"
    },
    {
        fieldName: "Trailer Drawbar",
        fieldId: "Trailer Drawbar",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555554138",
        _id: "641c0c2e690ed315f0e1f80f"
    },
    {
        fieldName: "Headlight Night Sensor",
        fieldId: "Headlight Night Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555606246",
        _id: "641c0c2e690ed315f0e1f810"
    },
    {
        fieldName: "Heated Mirrors",
        fieldId: "Heated Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555651630",
        _id: "641c0c2e690ed315f0e1f811"
    },
    {
        fieldName: "Parking Assistant",
        fieldId: "Parking Assistant",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555706729",
        _id: "641c0c2e690ed315f0e1f812"
    },
    {
        fieldName: "Rain Sensor",
        fieldId: "Rain Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555741609",
        _id: "641c0c2e690ed315f0e1f813"
    }
]

export const englishSafetyFeatures = [
    {
        fieldName: "Airbags",
        fieldId: "Airbags",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555867010",
        _id: "641c0c2e690ed315f0e1f814"
    },
    {
        fieldName: "Antilock Brakes(ABS)",
        fieldId: "Antilock Brakes(ABS)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555922483",
        _id: "641c0c2e690ed315f0e1f816"
    },
    {
        fieldName: "Traction Control",
        fieldId: "Traction Control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555966454",
        _id: "641c0c2e690ed315f0e1f817"
    },
    {
        fieldName: "Brake Assist",
        fieldId: "Brake Assist",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556003550",
        _id: "641c0c2e690ed315f0e1f818"
    },
    {
        fieldName: "Collision Warning and Detection",
        fieldId: "Collision Warning and Detection",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556062589",
        _id: "641c0c2e690ed315f0e1f819"
    },
    {
        fieldName: "Lane Departure and Blind Spot Warning",
        fieldId: "Lane Departure and Blind Spot Warning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556140900",
        _id: "641c0c2e690ed315f0e1f81a"
    },
    {
        fieldName: "Bling Spot warning",
        fieldId: "Bling Spot warning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556178178",
        _id: "641c0c2e690ed315f0e1f81b"
    },
    {
        fieldName: "Automatic Crash Alert System",
        fieldId: "Automatic Crash Alert System",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556274080",
        _id: "641c0c2e690ed315f0e1f81c"
    },
    {
        fieldName: "Convex rear-veiew mirror",
        fieldId: "Convex rear-veiew mirror",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556323178",
        _id: "641c0c2e690ed315f0e1f81d"
    },
    {
        fieldName: "Laminated and high strength automotive glass",
        fieldId: "Laminated and high strength automotive glass",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556738859",
        _id: "641c0c2e690ed315f0e1f81e"
    },
    {
        fieldName: "Vehicle overspeed warning devices",
        fieldId: "Vehicle overspeed warning devices",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556407240",
        _id: "641c0c2e690ed315f0e1f81f"
    },
    {
        fieldName: "Seatbelts",
        fieldId: "Seatbelts",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556452231",
        _id: "641c0c2e690ed315f0e1f820"
    },
    {
        fieldName: "Stability Control Systems",
        fieldId: "Stability Control Systems",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556496786",
        _id: "641c0c2e690ed315f0e1f821"
    },
    {
        fieldName: "Jack safety",
        fieldId: "Jack safety",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556528769",
        _id: "641c0c2e690ed315f0e1f822"
    },
    {
        fieldName: "Tire Failure indicator",
        fieldId: "Tire Failure indicator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556580606",
        _id: "641c0c2e690ed315f0e1f823"
    },
    {
        fieldName: "Armored",
        fieldId: "Armored",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556702144",
        _id: "641c0c2e690ed315f0e1f824"
    }
]


// Russian
export const russianDetails = [
    {
        fieldName: "год",
        fieldId: "Year",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688557362920",
        dropDown: [
            { fieldId: "2024", fieldValue: "2024" },
            { fieldId: "2023", fieldValue: "2023" },
            { fieldId: "2022", fieldValue: "2022" },
            { fieldId: "2021", fieldValue: "2021" },
            { fieldId: "2020", fieldValue: "2020" },
            { fieldId: "2019", fieldValue: "2019" },
            { fieldId: "2018", fieldValue: "2018" },
            { fieldId: "2017", fieldValue: "2017" },
            { fieldId: "2016", fieldValue: "2016" },
            { fieldId: "2015", fieldValue: "2015" },
            { fieldId: "2014", fieldValue: "2014" },
            { fieldId: "2013", fieldValue: "2013" },
            { fieldId: "2012", fieldValue: "2012" },
            { fieldId: "2011", fieldValue: "2011" },
            { fieldId: "2010", fieldValue: "2010" },
            { fieldId: "2009", fieldValue: "2009" },
            { fieldId: "2008", fieldValue: "2008" },
            { fieldId: "2007", fieldValue: "2007" },
            { fieldId: "2006", fieldValue: "2006" },
            { fieldId: "2005", fieldValue: "2005" },
            { fieldId: "2004", fieldValue: "2004" },
            { fieldId: "2003", fieldValue: "2003" },
            { fieldId: "2002", fieldValue: "2002" },
            { fieldId: "2001", fieldValue: "2001" },
            { fieldId: "2000", fieldValue: "2000" },
            { fieldId: "1999", fieldValue: "1999" },
            { fieldId: "1998", fieldValue: "1998" },
            { fieldId: "1997", fieldValue: "1997" },
            { fieldId: "1996", fieldValue: "1996" },
            { fieldId: "1995", fieldValue: "1995" },
            { fieldId: "1994", fieldValue: "1994" },
            { fieldId: "1993", fieldValue: "1993" },
            { fieldId: "1992", fieldValue: "1992" },
            { fieldId: "1991", fieldValue: "1991" },
            { fieldId: "1990", fieldValue: "1990" },
            { fieldId: "1980-1989", fieldValue: "1980-1989" },
            { fieldId: "1970-1979", fieldValue: "1970-1979" },
            { fieldId: "1060-1969", fieldValue: "1060-1969" },
            { fieldId: "1950-1959", fieldValue: "1950-1959" }
        ],
        _id: "641c0c2e690ed315f0e1f714"
    },
    {
        fieldName: "Тип передачи",
        fieldId: "Transmission Type",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1688711153648",
        dropDown: [
            { fieldId: "Automatic Transmission (AT)", fieldValue: "Автоматическая коробка передач (AT)" },
            { fieldId: "Manual Transmission (MT)", fieldValue: "Механическая коробка передач(MT)" },
            { fieldId: "Automated Manual Transmission (AMT)", fieldValue: "Автоматизированная механическая коробка передач (AM)" },
            { fieldId: "Continuously Variable Transmission (CVT)", fieldValue: "Бесступенчатая трансмиссия (CVT)" },
        ],
        _id: "641c0c2e690ed315f0e1f716"
    },
    {
        fieldName: "Размер двигателя",
        fieldId: "Engine Size",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559119224",
        dropDown: [
            { fieldId: "50cc", fieldValue: "50cc" },
            { fieldId: "75cc", fieldValue: "75cc" },
            { fieldId: "90cc", fieldValue: "90cc" },
            { fieldId: "100cc", fieldValue: "100cc" },
            { fieldId: "125cc", fieldValue: "125cc" },
            { fieldId: "150cc", fieldValue: "150cc" },
            { fieldId: "200cc", fieldValue: "200cc" },
            { fieldId: "300cc", fieldValue: "300cc" },
            { fieldId: "400cc", fieldValue: "400cc" },
            { fieldId: "500cc", fieldValue: "500cc" },
            { fieldId: "600cc", fieldValue: "600cc" },
            { fieldId: "700cc", fieldValue: "700cc" },
            { fieldId: "800cc", fieldValue: "800cc" },
            { fieldId: "1000cc", fieldValue: "1000cc" },
            { fieldId: "1200cc", fieldValue: "1200cc" },
            { fieldId: "1400cc", fieldValue: "1400cc" },
            { fieldId: "1600cc", fieldValue: "1600cc" },
            { fieldId: "1800cc", fieldValue: "1800cc" },
            { fieldId: "2000cc", fieldValue: "2000cc" },
            { fieldId: "2200cc", fieldValue: "2200cc" },
            { fieldId: "2400cc", fieldValue: "2400cc" },
            { fieldId: "2400cc", fieldValue: "2400cc" },
            { fieldId: "2600cc", fieldValue: "2600cc" },
            { fieldId: "2800cc", fieldValue: "2800cc" },
            { fieldId: "3000cc", fieldValue: "3000cc" }
        ],
        _id: "641c0c2e690ed315f0e1f717"
    },
    {
        fieldName: "Полный привод",
        fieldId: "Wheel Drive",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559168263",
        dropDown: [
            { fieldId: "2WD", fieldValue: "2WD" },
            { fieldId: "FWD", fieldValue: "FWD" },
            { fieldId: "RWD", fieldValue: "RWD" },
            { fieldId: "AWD", fieldValue: "AWD" },
            { fieldId: "4WD", fieldValue: "4WD" }
        ],
        _id: "641c0c2e690ed315f0e1f718"
    },
    {
        fieldName: "Тип топлива",
        fieldId: "Fuel Type",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559200775",
        dropDown: [
            { fieldId: "Petrol", fieldValue: "Бензин" },
            { fieldId: "Gasoline", fieldValue: "Бензин" },
            { fieldId: "Methanol", fieldValue: "Метанол" },
            { fieldId: "Diesel", fieldValue: "Дизель" },
            { fieldId: "Propane", fieldValue: "Пропан" },
            { fieldId: "Natural Gas", fieldValue: "Природный газ" },
            { fieldId: "Hydrogen", fieldValue: "Водород" },
            { fieldId: "cng", fieldValue: "снг" },
        ],
        _id: "641c0c2e690ed315f0e1f719"
    },
    {
        fieldName: "Валюта",
        fieldId: "Currency",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688562334438",
        dropDown: [
            { fieldId: "$", fieldValue: "$" },
            { fieldId: "€", fieldValue: "€" },
            { fieldId: "UZS", fieldValue: "UZS" }
        ],
        _id: "641c0c2e690ed315f0e1f71b"
    },
    {
        fieldName: "цель",
        fieldId: "Purpose",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690183200323",
        dropDown: [
            { fieldId: "Sale", fieldValue: "Продажа" },
            { fieldId: "Rent", fieldValue: "Аренда" },
            { fieldId: "Daily Rent", fieldValue: "Почасовая аренда" }
        ],
        _id: "641c0c2e690ed315f0e1f71c"
    },
    {
        fieldName: "Цвет",
        fieldId: "Color",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559372316",
        dropDown: [
            { fieldId: "White", fieldValue: "Белый" },
            { fieldId: "Black", fieldValue: "Черный" },
            { fieldId: "Red", fieldValue: "Красный" },
            { fieldId: "Gray", fieldValue: "Серый" },
            { fieldId: "Silver", fieldValue: "Серебро" },
            { fieldId: "Blue", fieldValue: "Голубой" },
            { fieldId: "Brown", fieldValue: "Коричневый" },
            { fieldId: "Green", fieldValue: "Зеленый" },
            { fieldId: "Orange", fieldValue: "Апельсин" },
            { fieldId: "Beige", fieldValue: "Бежевый" },
            { fieldId: "Purple", fieldValue: "Фиолетовый" },
            { fieldId: "Gold", fieldValue: "Золото" },
            { fieldId: "Yellow", fieldValue: "Желтый" }
        ],
        _id: "641c0c2e690ed315f0e1f71d"
    },
    {
        fieldName: "Гарантия",
        fieldId: "Warranty",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690191874408",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],
        _id: "641c0c2e690ed315f0e1f71e"
    },
    {
        fieldName: "От",
        fieldId: "From",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559453492",
        dropDown: [
            { fieldId: "Owner", fieldValue: "владелец" },
            { fieldId: "Dealer", fieldValue: "Дилер" }
        ],
        _id: "641c0c2e690ed315f0e1f71f"
    },
    {
        fieldName: "Состояние",
        fieldId: "Condition",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690192152428",
        dropDown: [
            { fieldId: "Used", fieldValue: "Использовал" },
            { fieldId: "New", fieldValue: "Новый" }
        ],

        _id: "641c0c2e690ed315f0e1f720"
    },
    {
        fieldName: "Бартер/обмен",
        fieldId: "Barter/Exchange",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559514220",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],
        _id: "641c0c2e690ed315f0e1f721"
    },
    {
        fieldName: "Идентификатор пластины",
        fieldId: "Plate ID",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559546011",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],
        _id: "641c0c2e690ed315f0e1f722"
    },
    {
        fieldName: "Видеозвонок",
        fieldId: "Video Call",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1704736201576_e10rteew_image.png",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],
        _id: "641c0c2e690ed315f0e1f723"
    }
]

export const russianInternalFeatures = [
    {
        fieldName: "Кожаное сиденье",
        fieldId: "Leather Seat",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1688551959801",
        _id: "641c0c2e690ed315f0e1f725"
    },
    {
        fieldName: "7-скоростная передача",
        fieldId: "7 Speed Gear",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552058676",
        _id: "641c0c2e690ed315f0e1f726"
    },
    {
        fieldName: "Кожаное рулевое колесо",
        fieldId: "Leather Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552107761",
        _id: "641c0c2e690ed315f0e1f727"
    },
    {
        fieldName: "Сиденья с памятью",
        fieldId: "Seats Memory",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552194379",
        _id: "641c0c2e690ed315f0e1f728"
    },
    {
        fieldName: "Охладитель сидений",
        fieldId: "Seats Cooler",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552350612",
        _id: "641c0c2e690ed315f0e1f729"
    },
    {
        fieldName: "Обогреватель сидений",
        fieldId: "Seat Heater",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552420497",
        _id: "641c0c2e690ed315f0e1f72a"
    },
    {
        fieldName: "Бортовой компьютер",
        fieldId: "Trip Computer",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552477839",
        _id: "641c0c2e690ed315f0e1f72b"
    },
    {
        fieldName: "Кнопка пуска/остановки",
        fieldId: "Start/Stop button",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552549763",
        _id: "641c0c2e690ed315f0e1f72c"
    },
    {
        fieldName: "Тканевое сиденье",
        fieldId: "Fabric Seat",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552604309",
        _id: "641c0c2e690ed315f0e1f72d"
    },
    {
        fieldName: "Аналоговый кондиционер",
        fieldId: "Analogue Air Conditioner",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552677230",
        _id: "641c0c2e690ed315f0e1f72e"
    },
    {
        fieldName: "Круиз-контроль",
        fieldId: "Cruise control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552732999",
        _id: "641c0c2e690ed315f0e1f72f"
    },
    {
        fieldName: "Камера заднего вида",
        fieldId: "Rear View Camera",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552787914",
        _id: "641c0c2e690ed315f0e1f730"
    },
    {
        fieldName: "Цифровой кондиционер",
        fieldId: "Digital Air Conditioner",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552826325",
        _id: "641c0c2e690ed315f0e1f731"
    },
    {
        fieldName: "Рулевое колесо с подогревом",
        fieldId: "Heated Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552911924",
        _id: "641c0c2e690ed315f0e1f732"
    },
    {
        fieldName: "Адаптивный круиз-контроль",
        fieldId: "Adaptive Cruise Control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552957415",
        _id: "641c0c2e690ed315f0e1f733"
    },
    {
        fieldName: "Камера переднего обзора",
        fieldId: "Front View Camera",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552994949",
        _id: "641c0c2e690ed315f0e1f734"
    },
    {
        fieldName: "Автоматическое затемнение заднего вида",
        fieldId: "Auto-Darkening Rear View",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553046653",
        _id: "641c0c2e690ed315f0e1f735"
    },
    {
        fieldName: "Регулируемое рулевое колесо",
        fieldId: "Adjustable Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553080182",
        _id: "641c0c2e690ed315f0e1f736"
    },
    {
        fieldName: "Холодильник",
        fieldId: "Refrigerator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553117182",
        _id: "641c0c2e690ed315f0e1f737"
    },
    {
        fieldName: "Радио",
        fieldId: "Radio",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553189272",
        _id: "641c0c2e690ed315f0e1f738"
    },
    {
        fieldName: "Кассетный проигрыватель",
        fieldId: "Cassette Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553220981",
        _id: "641c0c2e690ed315f0e1f739"
    },
    {
        fieldName: "DVD/CD-плеер",
        fieldId: "DVD/CD Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553365205",
        _id: "641c0c2e690ed315f0e1f73a"
    },
    {
        fieldName: "DVD-чейнджер",
        fieldId: "DVD Changer",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553411336",
        _id: "641c0c2e690ed315f0e1f73b"
    },
    {
        fieldName: "Блютуз",
        fieldId: "Bluetooth",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553447407",
        _id: "641c0c2e690ed315f0e1f73c"
    },
    {
        fieldName: "Подключение iPod",
        fieldId: "iPod Connection",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553495010",
        _id: "641c0c2e690ed315f0e1f73d"
    },
    {
        fieldName: "MP3-плеер",
        fieldId: "MP3 Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553536258",
        _id: "641c0c2e690ed315f0e1f73e"
    },
    {
        fieldName: "Навигация",
        fieldId: "Navigation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553636593",
        _id: "641c0c2e690ed315f0e1f73f"
    },
    {
        fieldName: "USB/AUX",
        fieldId: "USB/AUX",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553740372",
        _id: "641c0c2e690ed315f0e1f740"
    }
]

export const russianExternalFeatures = [
    {
        fieldName: "Биксеноновая фара",
        fieldId: "Bi Xenon Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553863236",
        _id: "641c0c2e690ed315f0e1f741"
    },
    {
        fieldName: "Мойка фар",
        fieldId: "Headlight Washing",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553917397",
        _id: "641c0c2e690ed315f0e1f742"
    },
    {
        fieldName: "Легкосплавное колесо",
        fieldId: "Alloy Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553957860",
        _id: "641c0c2e690ed315f0e1f743"
    },
    {
        fieldName: "Дефростер заднего окна",
        fieldId: "Rear Window Defroster",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554034414",
        _id: "641c0c2e690ed315f0e1f744"
    },
    {
        fieldName: "Светодиодная фара",
        fieldId: "LED Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554070642",
        _id: "641c0c2e690ed315f0e1f745"
    },
    {
        fieldName: "Противотуманный свет",
        fieldId: "Fog light",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554136155",
        _id: "641c0c2e690ed315f0e1f746"
    },
    {
        fieldName: "Электронные зеркала",
        fieldId: "Electronic Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554181983",
        _id: "641c0c2e690ed315f0e1f747"
    },
    {
        fieldName: "Задний датчик парковки",
        fieldId: "Back Parking Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554258581",
        _id: "641c0c2e690ed315f0e1f748"
    },
    {
        fieldName: "Передний датчик парковки",
        fieldId: "Front Parking Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554307958",
        _id: "641c0c2e690ed315f0e1f749"
    },
    {
        fieldName: "Люк в крыше",
        fieldId: "Sunroof",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555206549",
        _id: "641c0c2e690ed315f0e1f74a"
    },
    {
        fieldName: "Галогенная фара",
        fieldId: "Halogen Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555240192",
        _id: "641c0c2e690ed315f0e1f74b"
    },
    {
        fieldName: "Адаптивный головной свет",
        fieldId: "Adaptive Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555343733",
        _id: "641c0c2e690ed315f0e1f74c"
    },
    {
        fieldName: "Автоматически складывающиеся зеркала",
        fieldId: "Auto-Folding Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555464212",
        _id: "641c0c2e690ed315f0e1f74d"
    },
    {
        fieldName: "Панорамный стеклянный потолок",
        fieldId: "Panoramic Glass Ceiling",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555503342",
        _id: "641c0c2e690ed315f0e1f74e"
    },
    {
        fieldName: "Дышло для прицепа",
        fieldId: "Trailer Drawbar",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555554138",
        _id: "641c0c2e690ed315f0e1f74f"
    },
    {
        fieldName: "Фара ночная Senso",
        fieldId: "Headlight Night Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555606246",
        _id: "641c0c2e690ed315f0e1f750"
    },
    {
        fieldName: "Зеркала с подогревом",
        fieldId: "Heated Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555651630",
        _id: "641c0c2e690ed315f0e1f751"
    },
    {
        fieldName: "Помощник по парковке",
        fieldId: "Parking Assistant",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555706729",
        _id: "641c0c2e690ed315f0e1f752"
    },
    {
        fieldName: "Датчик дождя",
        fieldId: "Rain Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555741609",
        _id: "641c0c2e690ed315f0e1f753"
    }
]

export const russiansafetyFeatures = [
    {
        fieldName: "Подушки безопасности",
        fieldId: "Airbags",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555867010",
        _id: "641c0c2e690ed315f0e1f754"
    },
    {
        fieldName: "Антиблокировочная система тормозов",
        fieldId: "Antilock Brakes(ABS)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555922483",
        _id: "641c0c2e690ed315f0e1f756"
    },
    {
        fieldName: "Контроль тяги",
        fieldId: "Traction Control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555966454",
        _id: "641c0c2e690ed315f0e1f757"
    },
    {
        fieldName: "Ассистент торможения",
        fieldId: "Brake Assist",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556003550",
        _id: "641c0c2e690ed315f0e1f758"
    },
    {
        fieldName: "Предупреждение и обнаружение столкновений",
        fieldId: "Collision Warning and Detection",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556062589",
        _id: "641c0c2e690ed315f0e1f759"
    },
    {
        fieldName: "Выезд с полосы движения и предупреждение о слепых зонах",
        fieldId: "Lane Departure and Blind Spot Warning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556140900",
        _id: "641c0c2e690ed315f0e1f75a"
    },
    {
        fieldName: "Предупреждение о ярком пятне",
        fieldId: "Bling Spot warning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556178178",
        _id: "641c0c2e690ed315f0e1f75b"
    },
    {
        fieldName: "Автоматическая система предупреждения о дорожно-транспортных происшествиях",
        fieldId: "Automatic Crash Alert System",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556274080",
        _id: "641c0c2e690ed315f0e1f75c"
    },
    {
        fieldName: "Выпуклое зеркало заднего вида",
        fieldId: "Convex rear-veiew mirror",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556323178",
        _id: "641c0c2e690ed315f0e1f75d"
    },
    {
        fieldName: "Ламинированное и высокопрочное автомобильное стекло",
        fieldId: "Laminated and high strength automotive glass",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556738859",
        _id: "641c0c2e690ed315f0e1f75e"
    },
    {
        fieldName: "Устройства предупреждения о превышении скорости транспортного средства",
        fieldId: "Vehicle overspeed warning devices",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556407240",
        _id: "641c0c2e690ed315f0e1f75f"
    },
    {
        fieldName: "Ремни безопасности",
        fieldId: "Seatbelts",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556452231",
        _id: "641c0c2e690ed315f0e1f760"
    },
    {
        fieldName: "Системы контроля устойчивости",
        fieldId: "Stability Control Systems",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556496786",
        _id: "641c0c2e690ed315f0e1f761"
    },
    {
        fieldName: "Безопасность домкрата",
        fieldId: "Jack safety",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556528769",
        _id: "641c0c2e690ed315f0e1f762"
    },
    {
        fieldName: "Индикатор неисправности шины",
        fieldId: "Tire Failure indicator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556580606",
        _id: "641c0c2e690ed315f0e1f763"
    },
    {
        fieldName: "Бронированный",
        fieldId: "Armored",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556702144",
        _id: "641c0c2e690ed315f0e1f764"
    }
]

// Uzbek

export const uzbekDetails = [
    {
        fieldName: "Yil",
        fieldId: "Year",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688557362920",
        dropDown: [
            { fieldId: "2024", fieldValue: "2024" },
            { fieldId: "2023", fieldValue: "2023" },
            { fieldId: "2022", fieldValue: "2022" },
            { fieldId: "2021", fieldValue: "2021" },
            { fieldId: "2020", fieldValue: "2020" },
            { fieldId: "2019", fieldValue: "2019" },
            { fieldId: "2018", fieldValue: "2018" },
            { fieldId: "2017", fieldValue: "2017" },
            { fieldId: "2016", fieldValue: "2016" },
            { fieldId: "2015", fieldValue: "2015" },
            { fieldId: "2014", fieldValue: "2014" },
            { fieldId: "2013", fieldValue: "2013" },
            { fieldId: "2012", fieldValue: "2012" },
            { fieldId: "2011", fieldValue: "2011" },
            { fieldId: "2010", fieldValue: "2010" },
            { fieldId: "2009", fieldValue: "2009" },
            { fieldId: "2008", fieldValue: "2008" },
            { fieldId: "2007", fieldValue: "2007" },
            { fieldId: "2006", fieldValue: "2006" },
            { fieldId: "2005", fieldValue: "2005" },
            { fieldId: "2004", fieldValue: "2004" },
            { fieldId: "2003", fieldValue: "2003" },
            { fieldId: "2002", fieldValue: "2002" },
            { fieldId: "2001", fieldValue: "2001" },
            { fieldId: "2000", fieldValue: "2000" },
            { fieldId: "1999", fieldValue: "1999" },
            { fieldId: "1998", fieldValue: "1998" },
            { fieldId: "1997", fieldValue: "1997" },
            { fieldId: "1996", fieldValue: "1996" },
            { fieldId: "1995", fieldValue: "1995" },
            { fieldId: "1994", fieldValue: "1994" },
            { fieldId: "1993", fieldValue: "1993" },
            { fieldId: "1992", fieldValue: "1992" },
            { fieldId: "1991", fieldValue: "1991" },
            { fieldId: "1990", fieldValue: "1990" },
            { fieldId: "1980-1989", fieldValue: "1980-1989" },
            { fieldId: "1970-1979", fieldValue: "1970-1979" },
            { fieldId: "1060-1969", fieldValue: "1060-1969" },
            { fieldId: "1950-1959", fieldValue: "1950-1959" }
        ],
        _id: "641c0c2e690ed315f0e1f894"
    },
    {
        fieldName: "Transmissiya turi",
        fieldId: "Transmission Type",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1688711153648",
        dropDown: [
            { fieldId: "Automatic Transmission (AT)", fieldValue: "Avtomatik uzatish (AT)" },
            { fieldId: "Manual Transmission (MT)", fieldValue: "Qo'lda uzatish (MT)" },
            { fieldId: "Automated Manual Transmission (AMT)", fieldValue: "Avtomatlashtirilgan mexanik uzatish (AM)" },
            { fieldId: "Continuously Variable Transmission (CVT)", fieldValue: "Uzluksiz o'zgaruvchan uzatish (CVT)" },
        ],
        _id: "641c0c2e690ed315f0e1f896"
    },
    {
        fieldName: "G'ildirakli haydovchi",
        fieldId: "Wheel Drive",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559168263",
        dropDown: [
            { fieldId: "2WD", fieldValue: "2WD" },
            { fieldId: "FWD", fieldValue: "FWD" },
            { fieldId: "RWD", fieldValue: "RWD" },
            { fieldId: "AWD", fieldValue: "AWD" },
            { fieldId: "4WD", fieldValue: "4WD" }
        ],
        _id: "641c0c2e690ed315f0e1f898"
    },
    {
        fieldName: "Valyuta",
        fieldId: "Currency",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688562334438",
        dropDown: [
            { fieldId: "$", fieldValue: "$" },
            { fieldId: "€", fieldValue: "€" },
            { fieldId: "UZS", fieldValue: "UZS" }
        ],
        _id: "641c0c2e690ed315f0e1f89b"
    },
    {
        fieldName: "Maqsad",
        fieldId: "Purpose",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559265731",
        dropDown: [
            { fieldId: "Sale", fieldValue: "Sotish" },
            { fieldId: "Rent", fieldValue: "Ijara" },
            { fieldId: "Daily Rent", fieldValue: "Kunlik ijara" }
        ],
        _id: "641c0c2e690ed315f0e1f89c"
    },
    {
        fieldName: "Rang",
        fieldId: "Color",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559372316",
        dropDown: [
            { fieldId: "White", fieldValue: "Oq" },
            { fieldId: "Black", fieldValue: "Qora" },
            { fieldId: "Red", fieldValue: "Qizil" },
            { fieldId: "Gray", fieldValue: "Kulrang" },
            { fieldId: "Silver", fieldValue: "Kumush" },
            { fieldId: "Blue", fieldValue: "Moviy" },
            { fieldId: "Brown", fieldValue: "jigarrang" },
            { fieldId: "Green", fieldValue: "Yashil" },
            { fieldId: "Orange", fieldValue: "apelsin" },
            { fieldId: "Beige", fieldValue: "Bej" },
            { fieldId: "Purple", fieldValue: "Siyohrang" },
            { fieldId: "Gold", fieldValue: "Oltin" },
            { fieldId: "Yellow", fieldValue: "Sariq" }
        ],
        _id: "641c0c2e690ed315f0e1f89d"
    },
    {
        fieldName: "Kafolat",
        fieldId: "Warranty",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690191874408",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f89e"
    },
    {
        fieldName: "Kimdan",
        fieldId: "From",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559453492",
        dropDown: [
            { fieldId: "Owner", fieldValue: "Egasi" },
            { fieldId: "Dealer", fieldValue: "Diler" }
        ],

        _id: "641c0c2e690ed315f0e1f89f"
    },
    {
        fieldName: "Vaziyat",
        fieldId: "Condition",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690192152428",
        dropDown: [
            { fieldId: "Used", fieldValue: "Ishlatilgan" },
            { fieldId: "New", fieldValue: "Yangi" }
        ],

        _id: "641c0c2e690ed315f0e1f8a0"
    },
    {
        fieldName: "Barter/Almashtirish",
        fieldId: "Barter/Exchange",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559514220",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8a1"
    },
    {
        fieldName: "Plastinaning identifikatori",
        fieldId: "Plate ID",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559546011",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8a2"
    },
    {
        fieldName: "Video qo'ng'iroq",
        fieldId: "Video Call",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1704736201576_e10rteew_image.png",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8a3"
    },
    {
        fieldName: "Dvigatel hajmi",
        fieldId: "Engine Size",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559119224",
        dropDown: [
            { fieldId: "50cc", fieldValue: "50cc" },
            { fieldId: "75cc", fieldValue: "75cc" },
            { fieldId: "90cc", fieldValue: "90cc" },
            { fieldId: "100cc", fieldValue: "100cc" },
            { fieldId: "125cc", fieldValue: "125cc" },
            { fieldId: "150cc", fieldValue: "150cc" },
            { fieldId: "200cc", fieldValue: "200cc" },
            { fieldId: "300cc", fieldValue: "300cc" },
            { fieldId: "400cc", fieldValue: "400cc" },
            { fieldId: "500cc", fieldValue: "500cc" },
            { fieldId: "600cc", fieldValue: "600cc" },
            { fieldId: "700cc", fieldValue: "700cc" },
            { fieldId: "800cc", fieldValue: "800cc" },
            { fieldId: "1000cc", fieldValue: "1000cc" },
            { fieldId: "1200cc", fieldValue: "1200cc" },
            { fieldId: "1400cc", fieldValue: "1400cc" },
            { fieldId: "1600cc", fieldValue: "1600cc" },
            { fieldId: "1800cc", fieldValue: "1800cc" },
            { fieldId: "2000cc", fieldValue: "2000cc" },
            { fieldId: "2200cc", fieldValue: "2200cc" },
            { fieldId: "2400cc", fieldValue: "2400cc" },
            { fieldId: "2400cc", fieldValue: "2400cc" },
            { fieldId: "2600cc", fieldValue: "2600cc" },
            { fieldId: "2800cc", fieldValue: "2800cc" },
            { fieldId: "3000cc", fieldValue: "3000cc" }
        ],
        _id: "641c0c2e690ed315f0e1f8a4"
    },
    {
        fieldName: "Yoqilg'i turi",
        fieldId: "Fuel Type",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688559200775",
        dropDown: [
            { fieldId: "Petrol", fieldValue: "Benzin" },
            { fieldId: "Methanol", fieldValue: "Metanol" },
            { fieldId: "Hydrogen", fieldValue: "Vodorod" },
            { fieldId: "Gasoline", fieldValue: "Gazolin" },
            { fieldId: "Diesel", fieldValue: "Dizel" },
            { fieldId: "Propane", fieldValue: "Propan" },
            { fieldId: "Natural Gas", fieldValue: "Tabiiy gaz" },
            { fieldId: "cng", fieldValue: "cng" },
        ],
        _id: "651199d83820e00946024cef"
    }
]

export const uzbekInternalFeatures = [
    {
        fieldName: "Teri o'rindiq",
        fieldId: "Leather Seat",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1688551959801",
        _id: "641c0c2e690ed315f0e1f8a5"
    },
    {
        fieldName: "7 tezlikli vites",
        fieldId: "7 Speed Gear",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552058676",
        _id: "641c0c2e690ed315f0e1f8a6"
    },
    {
        fieldName: "Teri rul",
        fieldId: "Leather Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552107761",
        _id: "641c0c2e690ed315f0e1f8a7"
    },
    {
        fieldName: "O'rindiqlar xotirasi",
        fieldId: "Seats Memory",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552194379",
        _id: "641c0c2e690ed315f0e1f8a8"
    },
    {
        fieldName: "O'rindiqlar sovutgich",
        fieldId: "Seats Cooler",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552350612",
        _id: "641c0c2e690ed315f0e1f8a9"
    },
    {
        fieldName: "O'rindiq isitgichi",
        fieldId: "Seat Heater",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552420497",
        _id: "641c0c2e690ed315f0e1f8aa"
    },
    {
        fieldName: "Safar kompyuteri",
        fieldId: "Trip Computer",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552477839",
        _id: "641c0c2e690ed315f0e1f8ab"
    },
    {
        fieldName: "Boshlash/To'xtatish tugmasi",
        fieldId: "Start/Stop button",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552549763",
        _id: "641c0c2e690ed315f0e1f8ac"
    },
    {
        fieldName: "Mato o'rindiq",
        fieldId: "Fabric Seat",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552604309",
        _id: "641c0c2e690ed315f0e1f8ad"
    },
    {
        fieldName: "Analog konditsioner",
        fieldId: "Analogue Air Conditioner",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552677230",
        _id: "641c0c2e690ed315f0e1f8ae"
    },
    {
        fieldName: "Kruiz nazorati",
        fieldId: "Cruise control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552732999",
        _id: "641c0c2e690ed315f0e1f8af"
    },
    {
        fieldName: "Orqa ko'rinish kamerasi",
        fieldId: "Rear View Camera",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552787914",
        _id: "641c0c2e690ed315f0e1f8b0"
    },
    {
        fieldName: "Raqamli konditsioner",
        fieldId: "Digital Air Conditioner",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552826325",
        _id: "641c0c2e690ed315f0e1f8b1"
    },
    {
        fieldName: "Isitilgan rul",
        fieldId: "Heated Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552911924",
        _id: "641c0c2e690ed315f0e1f8b2"
    },
    {
        fieldName: "Moslashuvchan kruiz nazorati",
        fieldId: "Adaptive Cruise Control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552957415",
        _id: "641c0c2e690ed315f0e1f8b3"
    },
    {
        fieldName: "Old ko'rish kamerasi",
        fieldId: "Front View Camera",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688552994949",
        _id: "641c0c2e690ed315f0e1f8b4"
    },
    {
        fieldName: "Avtomatik qorayish Orqa ko'rinish",
        fieldId: "Auto-Darkening Rear View",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553046653",
        _id: "641c0c2e690ed315f0e1f8b5"
    },
    {
        fieldName: "Sozlanishi rul",
        fieldId: "Adjustable Steering Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553080182",
        _id: "641c0c2e690ed315f0e1f8b6"
    },
    {
        fieldName: "Muzlatgich",
        fieldId: "Refrigerator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553117182",
        _id: "641c0c2e690ed315f0e1f8b7"
    },
    {
        fieldName: "Radio",
        fieldId: "Radio",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553189272",
        _id: "641c0c2e690ed315f0e1f8b8"
    },
    {
        fieldName: "Kasseta pleer",
        fieldId: "Cassette Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553220981",
        _id: "641c0c2e690ed315f0e1f8b9"
    },
    {
        fieldName: "DVD/CD pleer",
        fieldId: "DVD/CD Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553365205",
        _id: "641c0c2e690ed315f0e1f8ba"
    },
    {
        fieldName: "DVD almashtirgich",
        fieldId: "DVD Changer",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553411336",
        _id: "641c0c2e690ed315f0e1f8bb"
    },
    {
        fieldName: "Bluetooth",
        fieldId: "Bluetooth",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553447407",
        _id: "641c0c2e690ed315f0e1f8bc"
    },
    {
        fieldName: "iPod ulanishi",
        fieldId: "iPod Connection",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553495010",
        _id: "641c0c2e690ed315f0e1f8bd"
    },
    {
        fieldName: "MP3 pleer",
        fieldId: "MP3 Player",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553536258",
        _id: "641c0c2e690ed315f0e1f8be"
    },
    {
        fieldName: "Navigatsiya",
        fieldId: "Navigation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553636593",
        _id: "641c0c2e690ed315f0e1f8bf"
    },
    {
        fieldName: "USB/AUX",
        fieldId: "USB/AUX",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553740372",
        _id: "641c0c2e690ed315f0e1f8c0"
    }
]

export const uzbekExternalFeatures = [
    {
        fieldName: "Bi ksenon fara",
        fieldId: "Bi Xenon Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553863236",
        _id: "641c0c2e690ed315f0e1f8c1"
    },
    {
        fieldName: "Farlarni yuvish",
        fieldId: "Headlight Washing",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553917397",
        _id: "641c0c2e690ed315f0e1f8c2"
    },
    {
        fieldName: "Qotishma g'ildirak",
        fieldId: "Alloy Wheel",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688553957860",
        _id: "641c0c2e690ed315f0e1f8c3"
    },
    {
        fieldName: "Orqa oynani muzdan tushirish",
        fieldId: "Rear Window Defroster",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554034414",
        _id: "641c0c2e690ed315f0e1f8c4"
    },
    {
        fieldName: "LED fara",
        fieldId: "LED Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554070642",
        _id: "641c0c2e690ed315f0e1f8c5"
    },
    {
        fieldName: "Tuman nuri",
        fieldId: "Fog light",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554136155",
        _id: "641c0c2e690ed315f0e1f8c6"
    },
    {
        fieldName: "Elektron oynalar",
        fieldId: "Electronic Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554181983",
        _id: "641c0c2e690ed315f0e1f8c7"
    },
    {
        fieldName: "Orqa to'xtash sensori",
        fieldId: "Back Parking Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554258581",
        _id: "641c0c2e690ed315f0e1f8c8"
    },
    {
        fieldName: "Old to'xtash sensori",
        fieldId: "Front Parking Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688554307958",
        _id: "641c0c2e690ed315f0e1f8c9"
    },
    {
        fieldName: "Lyuk",
        fieldId: "Sunroof",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555206549",
        _id: "641c0c2e690ed315f0e1f8ca"
    },
    {
        fieldName: "Galogen fara",
        fieldId: "Halogen Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555240192",
        _id: "641c0c2e690ed315f0e1f8cb"
    },
    {
        fieldName: "Moslashuvchan fara",
        fieldId: "Adaptive Headlight",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555343733",
        _id: "641c0c2e690ed315f0e1f8cc"
    },
    {
        fieldName: "Avtomatik yig'iladigan nometall",
        fieldId: "Auto-Folding Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555464212",
        _id: "641c0c2e690ed315f0e1f8cd"
    },
    {
        fieldName: "Panoramik shisha ship",
        fieldId: "Panoramic Glass Ceiling",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555503342",
        _id: "641c0c2e690ed315f0e1f8ce"
    },
    {
        fieldName: "Treyler tortish paneli",
        fieldId: "Trailer Drawbar",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555554138",
        _id: "641c0c2e690ed315f0e1f8cf"
    },
    {
        fieldName: "Far kechasi sensori",
        fieldId: "Headlight Night Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555606246",
        _id: "641c0c2e690ed315f0e1f8d0"
    },
    {
        fieldName: "Issiq oynalar",
        fieldId: "Heated Mirrors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555651630",
        _id: "641c0c2e690ed315f0e1f8d1"
    },
    {
        fieldName: "Avtoturargoh yordamchisi",
        fieldId: "Parking Assistant",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555706729",
        _id: "641c0c2e690ed315f0e1f8d2"
    },
    {
        fieldName: "Yomg'ir sensori",
        fieldId: "Rain Sensor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555741609",
        _id: "641c0c2e690ed315f0e1f8d3"
    }
]

export const uzbekSafetyFeatures = [
    {
        fieldName: "Havo yostiqchalari",
        fieldId: "Airbags",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555867010",
        _id: "641c0c2e690ed315f0e1f8d4"
    },
    {
        fieldName: "Bloklashga qarshi tormozlar (ABS)",
        fieldId: "Antilock Brakes(ABS)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555922483",
        _id: "641c0c2e690ed315f0e1f8d6"
    },
    {
        fieldName: "Traktsiya nazorati",
        fieldId: "Traction Control",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688555966454",
        _id: "641c0c2e690ed315f0e1f8d7"
    },
    {
        fieldName: "Tormoz yordamchisi",
        fieldId: "Brake Assist",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556003550",
        _id: "641c0c2e690ed315f0e1f8d8"
    },
    {
        fieldName: "To'qnashuv haqida ogohlantirish va aniqlash",
        fieldId: "Collision Warning and Detection",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556062589",
        _id: "641c0c2e690ed315f0e1f8d9"
    },
    {
        fieldName: "Chiziqdan chiqish va ko'r nuqta haqida ogohlantirish",
        fieldId: "Lane Departure and Blind Spot Warning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556140900",
        _id: "641c0c2e690ed315f0e1f8da"
    },
    {
        fieldName: "Bling Spot ogohlantirishi",
        fieldId: "Bling Spot warning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556178178",
        _id: "641c0c2e690ed315f0e1f8db"
    },
    {
        fieldName: "Avtomatik halokat haqida ogohlantirish tizimi",
        fieldId: "Automatic Crash Alert System",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556274080",
        _id: "641c0c2e690ed315f0e1f8dc"
    },
    {
        fieldName: "Qavariq orqa ko'rinish oynasi",
        fieldId: "Convex rear-veiew mirror",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556323178",
        _id: "641c0c2e690ed315f0e1f8dd"
    },
    {
        fieldName: "Laminatsiyalangan va yuqori quvvatli avtomobil oynalari",
        fieldId: "Laminated and high strength automotive glass",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556738859",
        _id: "641c0c2e690ed315f0e1f8de"
    },
    {
        fieldName: "Avtomobilning haddan tashqari tezligi haqida ogohlantiruvchi qurilmalar",
        fieldId: "Vehicle overspeed warning devices",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556407240",
        _id: "641c0c2e690ed315f0e1f8df"
    },
    {
        fieldName: "Havfsizlik kamarlari",
        fieldId: "Seatbelts",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556452231",
        _id: "641c0c2e690ed315f0e1f8e0"
    },
    {
        fieldName: "Barqarorlikni nazorat qilish tizimlari",
        fieldId: "Stability Control Systems",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556496786",
        _id: "641c0c2e690ed315f0e1f8e1"
    },
    {
        fieldName: "Jek xavfsizligi",
        fieldId: "Jack safety",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556528769",
        _id: "641c0c2e690ed315f0e1f8e2"
    },
    {
        fieldName: "Shina buzilishi ko'rsatkichi",
        fieldId: "Tire Failure indicator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556580606",
        _id: "641c0c2e690ed315f0e1f8e3"
    },
    {
        fieldName: "Zirhli",
        fieldId: "Armored",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688556702144",
        _id: "641c0c2e690ed315f0e1f8e4"
    }
]



// Body Parts
export const englishBodyParts = [
    { fieldName: 'Front Bumper', fieldId: 'Front Bumper' },
    { fieldName: 'Engine Bonnet', fieldId: 'Engine Bonnet' },
    { fieldName: 'Ceiling', fieldId: 'Ceiling' },
    { fieldName: 'Right Front Fendor', fieldId: 'Right Front Fendor' },
    { fieldName: 'Right Front Door', fieldId: 'Right Front Door' },
    { fieldName: 'Right Rear Door', fieldId: 'Right Rear Door' },
    { fieldName: 'Left Front Fendor', fieldId: 'Left Front Fendor' },
    { fieldName: 'Right Rear Fendor', fieldId: 'Right Rear Fendor' },
    { fieldName: 'Left Front Door', fieldId: 'Left Front Door' },
    { fieldName: 'Left Rear Door', fieldId: 'Left Rear Door' },
    { fieldName: 'Left Rear Fendor', fieldId: 'Left Rear Fendor' },
    { fieldName: 'Tail gate', fieldId: 'Tail gate' },
    { fieldName: 'Rear Bumper', fieldId: 'Rear Bumper' }
]



export const russianBodyParts = [
    { fieldName: 'Передний бампер', fieldId: 'Front Bumper' },
    { fieldName: 'Капот двигателя', fieldId: 'Engine Bonnet' },
    { fieldName: 'Потолок', fieldId: 'Ceiling' },
    { fieldName: 'Правое переднее крыло', fieldId: 'Right Front Fendor' },
    { fieldName: 'Правая передняя дверь', fieldId: 'Right Front Door' },
    { fieldName: 'Правая задняя дверь', fieldId: 'Right Rear Door' },
    { fieldName: 'Левое переднее крыло', fieldId: 'Left Front Fendor' },
    { fieldName: 'Правое заднее крыло', fieldId: 'Right Rear Fendor' },
    { fieldName: 'Левая передняя дверь', fieldId: 'Left Front Door' },
    { fieldName: 'Левая задняя дверь', fieldId: 'Left Rear Door' },
    { fieldName: 'Левое заднее крыло', fieldId: 'Left Rear Fendor' },
    { fieldName: 'Хвостовые ворота', fieldId: 'Tail gate' },
    { fieldName: 'Задний бампер', fieldId: 'Rear Bumper' }
]

export const uzbekBodyParts = [
    { fieldName: 'Old bamper', fieldId: 'Front Bumper' },
    { fieldName: `Dvigatel qopqog'i`, fieldId: 'Engine Bonnet' },
    { fieldName: 'Shift', fieldId: 'Ceiling' },
    { fieldName: `O'ng old Fendor`, fieldId: 'Right Front Fendor' },
    { fieldName: `O'ng old eshik`, fieldId: 'Right Front Door' },
    { fieldName: `O'ng orqa eshik`, fieldId: 'Right Rear Door' },
    { fieldName: `Chap old Fendor`, fieldId: 'Left Front Fendor' },
    { fieldName: `O'ng orqa Fendor`, fieldId: 'Right Rear Fendor' },
    { fieldName: `Chap old eshik`, fieldId: 'Left Front Door' },
    { fieldName: `Chap orqa eshik`, fieldId: 'Left Rear Door' },
    { fieldName: `Chap orqa Fendor`, fieldId: 'Left Rear Fendor' },
    { fieldName: `Orqa eshik`, fieldId: 'Tail gate' },
    { fieldName: `Orqa bamper`, fieldId: 'Rear Bumper' }
]