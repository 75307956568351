export const englishDetails = [
    {
        fieldName: "Currency",
        fieldId: "Currency",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1696583821236",
        dropDown: [
            { fieldId: "$", fieldValue: "$" },
            { fieldId: "€", fieldValue: "€" },
            { fieldId: "UZS", fieldValue: "UZS" }
        ],        
        _id: "641c0c2e690ed315f0e1f825"
    },
    {
        fieldName: "Floor Location",
        fieldId: "Floor Location",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560086649",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" },
            { fieldId: "11", fieldValue: "11" },
            { fieldId: "12", fieldValue: "12" },
            { fieldId: "13", fieldValue: "13" },
            { fieldId: "14", fieldValue: "14" },
            { fieldId: "15", fieldValue: "15" },
            { fieldId: "16", fieldValue: "16" },
            { fieldId: "17", fieldValue: "17" },
            { fieldId: "18", fieldValue: "18" },
            { fieldId: "19", fieldValue: "19" },
            { fieldId: "20", fieldValue: "20" }
        ],
        
        
        _id: "641c0c2e690ed315f0e1f826"
    },
    {
        fieldName: "From whom",
        fieldId: "From whom",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690183818545",
        dropDown: [
            { fieldId: "Owner", fieldValue: "Owner" },
            { fieldId: "Real estate agency", fieldValue: "Real estate agency" }
        ],
        
        _id: "641c0c2e690ed315f0e1f827"
    },
    {
        fieldName: "Purpose",
        fieldId: "Purpose",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1696585086544",
        dropDown: [
            { fieldId: "Sale", fieldValue: "Sale" },
            { fieldId: "Rent", fieldValue: "Rent" },
            { fieldId: "Daily Rent", fieldValue: "Daily Rent" }
        ],        
        _id: "641c0c2e690ed315f0e1f828"
    },
    {
        fieldName: "Saloon",
        fieldId: "Saloon",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1696588261427",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" }
        ],
        
        _id: "641c0c2e690ed315f0e1f829"
    },
    {
        fieldName: "Bedrooms",
        fieldId: "Bedrooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560433432",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" }
        ],        
        _id: "641c0c2e690ed315f0e1f82a"
    },
    {
        fieldName: "Bathrooms",
        fieldId: "Bathrooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560508480",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" }
        ],
        
        _id: "641c0c2e690ed315f0e1f82b"
    },
    {
        fieldName: "Furnished",
        fieldId: "Furnished",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184245902",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        
        _id: "641c0c2e690ed315f0e1f82c"
    },
    {
        fieldName: "Balcony",
        fieldId: "Balcony",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560577225",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" }
        ],
        
        _id: "641c0c2e690ed315f0e1f82d"
    },
    {
        fieldName: "Video Call",
        fieldId: "Video Call",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560625960",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f82e"
    },
    {
        fieldName: "Usage Status",
        fieldId: "Usage Status",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184463460",
        dropDown: [
            { fieldId: "Rented", fieldValue: "Rented" },
            { fieldId: "Empty", fieldValue: "Empty" }
        ],
        
        _id: "641c0c2e690ed315f0e1f82f"
    },
    {
        fieldName: "Facade",
        fieldId: "Facade",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184541392",
        dropDown: [
            { fieldId: "North", fieldValue: "North" },
            { fieldId: "South", fieldValue: "South" },
            { fieldId: "East", fieldValue: "East" },
            { fieldId: "West", fieldValue: "West" }
        ],
        
        _id: "641c0c2e690ed315f0e1f830"
    },
    {
        fieldName: "Inside Complex",
        fieldId: "Inside Complex",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184949009",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        
        _id: "641c0c2e690ed315f0e1f831"
    },
    {
        fieldName: "Heating System",
        fieldId: "Heating System",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560817795",
        dropDown: [
            { fieldId: "Stove", fieldValue: "Stove" },
            { fieldId: "Natural Gas Stove", fieldValue: "Natural Gas Stove" },
            { fieldId: "Room Heater", fieldValue: "Room Heater" },
            { fieldId: "Central Heating", fieldValue: "Central Heating" },
            { fieldId: "Floor Heating", fieldValue: "Floor Heating" },
            { fieldId: "Air conditioning ", fieldValue: "Air conditioning " },
            { fieldId: "Fan Coil Unit", fieldValue: "Fan Coil Unit" },
            { fieldId: "Solar Energy", fieldValue: "Solar Energy" },
            { fieldId: "Electrical Radiator", fieldValue: "Electrical Radiator" },
            { fieldId: "Fireplace", fieldValue: "Fireplace" },
            { fieldId: "Geothermal", fieldValue: "Geothermal" }
        ],
        
        _id: "641c0c2e690ed315f0e1f832"
    },
    {
        fieldName: "Building Age",
        fieldId: "Building Age",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1690185207726",
        dropDown: [
            { fieldId: "New", fieldValue: "New" },
            { fieldId: "1 Years", fieldValue: "1 Years" },
            { fieldId: "2 Years", fieldValue: "2 Years" },
            { fieldId: "3 Years", fieldValue: "3 Years" },
            { fieldId: "4 Years", fieldValue: "4 Years" },
            { fieldId: "5 Years", fieldValue: "5 Years" },
            { fieldId: "6 Years", fieldValue: "6 Years" },
            { fieldId: "7 Years", fieldValue: "7 Years" },
            { fieldId: "8 Years", fieldValue: "8 Years" },
            { fieldId: "9 Years", fieldValue: "9 Years" },
            { fieldId: "10 Years", fieldValue: "10 Years" },
            { fieldId: "10+ Years", fieldValue: "10+ Years" }
        ],
        
        _id: "641c0c2e690ed315f0e1f833"
    },
    {
        fieldName: "Building Floors",
        fieldId: "Building Floors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688561034038",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" }
        ],
        
        _id: "641c0c2e690ed315f0e1f834"
    },
    {
        fieldName: "Title Deed Status",
        fieldId: "Title Deed Status",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690185351107",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        
        _id: "641c0c2e690ed315f0e1f835"
    },
    {
        fieldName: "Eligible for Mortgage",
        fieldId: "Eligible for Mortgage",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690186029113",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        
        _id: "641c0c2e690ed315f0e1f836"
    },
    {
        fieldName: "Barter / Exchange",
        fieldId: "Barter / Exchange",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690187050272",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        
        _id: "641c0c2e690ed315f0e1f837"
    },
    {
        fieldName: "Number of Rooms",
        fieldId: "Number of Rooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628428763",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" },
            { fieldId: "20+", fieldValue: "20+" },
            { fieldId: "30+", fieldValue: "30+" },
            { fieldId: "40+", fieldValue: "40+" },
            { fieldId: "50+", fieldValue: "50+" }
        ],
        
        _id: "641c0c2e690ed315f0e1f838"
    },
    {
        fieldName: "Inside Industrial Area",
        fieldId: "Inside Industrial Area",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628539299",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f839"
    },
    {
        fieldName: "Kitchen",
        fieldId: "Kitchen",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628579364",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f83a"
    },
    {
        fieldName: "Meeting Rooms",
        fieldId: "Meeting Rooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628642343",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f83b"
    },
    {
        fieldName: "Parking",
        fieldId: "Parking",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624292370",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f83c"
    },
    {
        fieldName: "Capacity",
        fieldId: "Capacity",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628698590",
        dropDown: [
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" }
        ],
        
        _id: "641c0c2e690ed315f0e1f83d"
    },
    {
        fieldName: "Number of Shops",
        fieldId: "Number of Shops",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628738275",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" },
            { fieldId: "20+", fieldValue: "20+" },
            { fieldId: "30+", fieldValue: "30+" }
        ],
        
        _id: "641c0c2e690ed315f0e1f83e"
    },
    {
        fieldName: "Restaurants",
        fieldId: "Restaurants",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624354497",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f83f"
    },
    {
        fieldName: "Bars",
        fieldId: "Bars",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628777818",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" }
        ],
        
        _id: "641c0c2e690ed315f0e1f840"
    },
    {
        fieldName: "Swimming pool",
        fieldId: "Swimming pool",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624423937",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Yes" },
            { fieldId: "No", fieldValue: "No" }
        ],
        _id: "641c0c2e690ed315f0e1f841"
    },
    {
        fieldName: "Number of floors",
        fieldId: "Number of floors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628818781",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" }
        ],
        
        _id: "641c0c2e690ed315f0e1f842"
    }
]

export const englishInternalFeatures = [
    {
        fieldName: "Fire Alarm",
        fieldId: "Fire Alarm",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630138779",
        _id: "641c0c2e690ed315f0e1f843"
    },
    {
        fieldName: "Open kitchen",
        fieldId: "Open kitchen",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630180257",
        _id: "641c0c2e690ed315f0e1f844"
    },
    {
        fieldName: "Washing Machine",
        fieldId: "Washing Machine",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630244312",
        _id: "641c0c2e690ed315f0e1f845"
    },
    {
        fieldName: "Shower cabin",
        fieldId: "Shower cabin",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630349692",
        _id: "641c0c2e690ed315f0e1f846"
    },
    {
        fieldName: "Fibre optics Internet",
        fieldId: "Fibre optics Internet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630380677",
        _id: "641c0c2e690ed315f0e1f847"
    },
    {
        fieldName: "Water heater",
        fieldId: "Water heater",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630445829",
        _id: "641c0c2e690ed315f0e1f848"
    },
    {
        fieldName: "Buit-in oven",
        fieldId: "Buit-in oven",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630472293",
        _id: "641c0c2e690ed315f0e1f849"
    },
    {
        fieldName: "Wallpaper",
        fieldId: "Wallpaper",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630502909",
        _id: "641c0c2e690ed315f0e1f84a"
    },
    {
        fieldName: "Dressing room",
        fieldId: "Dressing room",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630524910",
        _id: "641c0c2e690ed315f0e1f84b"
    },
    {
        fieldName: "Kithchen Gas",
        fieldId: "Kithchen Gas",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630555364",
        _id: "641c0c2e690ed315f0e1f84c"
    },
    {
        fieldName: "Area Net",
        fieldId: "Area Net",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630588177",
        _id: "641c0c2e690ed315f0e1f84d"
    },
    {
        fieldName: "Fireplace",
        fieldId: "Fireplace",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630619563",
        _id: "641c0c2e690ed315f0e1f84e"
    },
    {
        fieldName: "Wi-Fi",
        fieldId: "Wi-Fi",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630646540",
        _id: "641c0c2e690ed315f0e1f84f"
    },
    {
        fieldName: "Smart House",
        fieldId: "Smart House",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630682136",
        _id: "641c0c2e690ed315f0e1f850"
    },
    {
        fieldName: "Thief Alarm system",
        fieldId: "Thief Alarm system",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630743790",
        _id: "641c0c2e690ed315f0e1f851"
    },
    {
        fieldName: "Dishwasher",
        fieldId: "Dishwasher",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630776486",
        _id: "641c0c2e690ed315f0e1f852"
    },
    {
        fieldName: "Laundry room",
        fieldId: "Laundry room",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630805579",
        _id: "641c0c2e690ed315f0e1f853"
    },
    {
        fieldName: "Master Bathroom",
        fieldId: "Master Bathroom",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630838849",
        _id: "641c0c2e690ed315f0e1f854"
    },
    {
        fieldName: "Closet",
        fieldId: "Closet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630922248",
        _id: "641c0c2e690ed315f0e1f855"
    },
    {
        fieldName: "Air conditioning",
        fieldId: "Air conditioning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630966301",
        _id: "641c0c2e690ed315f0e1f856"
    },
    {
        fieldName: "Furniture",
        fieldId: "Furniture",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630997947",
        _id: "641c0c2e690ed315f0e1f857"
    },
    {
        fieldName: "Terace",
        fieldId: "Terace",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631049157",
        _id: "641c0c2e690ed315f0e1f858"
    },
    {
        fieldName: "Face Recognition & Fingerprint",
        fieldId: "Face Recognition & Fingerprint",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631074506",
        _id: "641c0c2e690ed315f0e1f859"
    },
    {
        fieldName: "Barbecue",
        fieldId: "Barbecue",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631380504",
        _id: "641c0c2e690ed315f0e1f85a"
    },
    {
        fieldName: "Fridge",
        fieldId: "Fridge",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631154564",
        _id: "641c0c2e690ed315f0e1f85b"
    },
    {
        fieldName: "Steel door",
        fieldId: "Steel door",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631220062",
        _id: "641c0c2e690ed315f0e1f85c"
    },
    {
        fieldName: "Oven",
        fieldId: "Oven",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631253138",
        _id: "641c0c2e690ed315f0e1f85d"
    },
    {
        fieldName: "Jacuzzi",
        fieldId: "Jacuzzi",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631275553",
        _id: "641c0c2e690ed315f0e1f85e"
    },
    {
        fieldName: "Bathub",
        fieldId: "Bathub",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631317104",
        _id: "641c0c2e690ed315f0e1f85f"
    },
    {
        fieldName: "Euro decor",
        fieldId: "Euro decor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631352621",
        _id: "641c0c2e690ed315f0e1f860"
    }
]

export const englishExternalFeatures = [
    {
        fieldName: "Camera system",
        fieldId: "Camera system",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625379437",
        _id: "641c0c2e690ed315f0e1f862"
    },
    {
        fieldName: "Covered Garage",
        fieldId: "Covered Garage",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625420758",
        _id: "641c0c2e690ed315f0e1f863"
    },
    {
        fieldName: "Cable TV",
        fieldId: "Cable TV",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688629885055",
        _id: "641c0c2e690ed315f0e1f864"
    },
    {
        fieldName: "Lift",
        fieldId: "Lift",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625589448",
        _id: "641c0c2e690ed315f0e1f865"
    },
    {
        fieldName: "Thermal Insultation",
        fieldId: "Thermal Insultation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625629237",
        _id: "641c0c2e690ed315f0e1f866"
    },
    {
        fieldName: "Private Pool",
        fieldId: "Private Pool",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625668725",
        _id: "641c0c2e690ed315f0e1f867"
    },
    {
        fieldName: "Sound insultation",
        fieldId: "Sound insultation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625766687",
        _id: "641c0c2e690ed315f0e1f868"
    },
    {
        fieldName: "Satellite",
        fieldId: "Satellite",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625804116",
        _id: "641c0c2e690ed315f0e1f869"
    },
    {
        fieldName: "Swimming Pool (Indoor)",
        fieldId: "Swimming Pool (Indoor)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688629921792",
        _id: "641c0c2e690ed315f0e1f86a"
    },
    {
        fieldName: "Swimming Pool (Outdoor)",
        fieldId: "Swimming Pool (Outdoor)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625845481",
        _id: "641c0c2e690ed315f0e1f86b"
    },
    {
        fieldName: "Generator",
        fieldId: "Generator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625918759",
        _id: "641c0c2e690ed315f0e1f86c"
    },
    {
        fieldName: "Doorman",
        fieldId: "Doorman",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625947861",
        _id: "641c0c2e690ed315f0e1f86d"
    },
    {
        fieldName: "Playground",
        fieldId: "Playground",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626018598",
        _id: "641c0c2e690ed315f0e1f86e"
    },
    {
        fieldName: "Sports Field",
        fieldId: "Sports Field",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626057931",
        _id: "641c0c2e690ed315f0e1f86f"
    },
    {
        fieldName: "Fire escape",
        fieldId: "Fire escape",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626133236",
        _id: "641c0c2e690ed315f0e1f870"
    },
    {
        fieldName: "Sauna",
        fieldId: "Sauna",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626171963",
        _id: "641c0c2e690ed315f0e1f871"
    },
    {
        fieldName: "Water tank",
        fieldId: "Water tank",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626243162",
        _id: "641c0c2e690ed315f0e1f872"
    }
]

export const englishEnvironment = [
    {
        fieldName: "Nearby Park",
        fieldId: "Nearby Park",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626667209",
        _id: "641c0c2e690ed315f0e1f873"
    },
    {
        fieldName: "Shopping Mall",
        fieldId: "Shopping Mall",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626709275",
        _id: "641c0c2e690ed315f0e1f874"
    },
    {
        fieldName: "Mosque",
        fieldId: "Mosque",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626738271",
        _id: "641c0c2e690ed315f0e1f875"
    },
    {
        fieldName: "Gym",
        fieldId: "Gym",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626765580",
        _id: "641c0c2e690ed315f0e1f876"
    },
    {
        fieldName: "Park",
        fieldId: "Park",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626800015",
        _id: "641c0c2e690ed315f0e1f877"
    },
    {
        fieldName: "School",
        fieldId: "School",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626828647",
        _id: "641c0c2e690ed315f0e1f878"
    },
    {
        fieldName: "University",
        fieldId: "University",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626859153",
        _id: "641c0c2e690ed315f0e1f879"
    },
    {
        fieldName: "Policy Station",
        fieldId: "Policy Station",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626925597",
        _id: "641c0c2e690ed315f0e1f87b"
    }
]

export const englishAccessToTransportation = [
    {
        fieldName: "Metro",
        fieldId: "Metro",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627218084",
        _id: "641c0c2e690ed315f0e1f87c"
    },
    {
        fieldName: "Minibus",
        fieldId: "Minibus",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627487384",
        _id: "641c0c2e690ed315f0e1f87d"
    },
    {
        fieldName: "Highway",
        fieldId: "Highway",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627747663",
        _id: "641c0c2e690ed315f0e1f87e"
    },
    {
        fieldName: "Bus Stop",
        fieldId: "Bus Stop",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627779242",
        _id: "641c0c2e690ed315f0e1f87f"
    },
    {
        fieldName: "Railway",
        fieldId: "Railway",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627840835",
        _id: "641c0c2e690ed315f0e1f880"
    },
    {
        fieldName: "Airport",
        fieldId: "Airport",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627879748",
        _id: "641c0c2e690ed315f0e1f881"
    },
    {
        fieldName: "Cable Car",
        fieldId: "Cable Car",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627927715",
        _id: "641c0c2e690ed315f0e1f882"
    }
]

export const englishView = [
    {
        fieldName: "City",
        fieldId: "City",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635771770",
        _id: "641c0c2e690ed315f0e1f883"
    },
    {
        fieldName: "Inside the Complex",
        fieldId: "Inside the Complex",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635944990",
        _id: "641c0c2e690ed315f0e1f884"
    },
    {
        fieldName: "Street",
        fieldId: "Street",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635973705",
        _id: "641c0c2e690ed315f0e1f885"
    },
    {
        fieldName: "Main Road",
        fieldId: "Main Road",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636264195",
        _id: "641c0c2e690ed315f0e1f886"
    },
    {
        fieldName: "Sea",
        fieldId: "Sea",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636301920",
        _id: "641c0c2e690ed315f0e1f887"
    },
    {
        fieldName: "River",
        fieldId: "River",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636331827",
        _id: "641c0c2e690ed315f0e1f888"
    },
    {
        fieldName: "Lake",
        fieldId: "Lake",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636383484",
        _id: "641c0c2e690ed315f0e1f889"
    },
    {
        fieldName: "Forest",
        fieldId: "Forest",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636413169",
        _id: "641c0c2e690ed315f0e1f88a"
    },
    {
        fieldName: "Desert",
        fieldId: "Desert",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636448339",
        _id: "641c0c2e690ed315f0e1f88b"
    },
    {
        fieldName: "Panorama",
        fieldId: "Panorama",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636476982",
        _id: "641c0c2e690ed315f0e1f88c"
    }
]

export const englishDisabledFacilities = [
    {
        fieldName: "Car Parking",
        fieldId: "Car Parking",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627986561",
        _id: "641c0c2e690ed315f0e1f88d"
    },
    {
        fieldName: "Stairs",
        fieldId: "Stairs",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628016119",
        _id: "641c0c2e690ed315f0e1f88e"
    },
    {
        fieldName: "Lift",
        fieldId: "Lift",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628050777",
        _id: "641c0c2e690ed315f0e1f88f"
    },
    {
        fieldName: "Charging place for Electronical vehicles",
        fieldId: "Charging place for Electronical vehicles",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628084392",
        _id: "641c0c2e690ed315f0e1f890"
    },
    {
        fieldName: "Toilet",
        fieldId: "Toilet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628112730",
        _id: "641c0c2e690ed315f0e1f891"
    }
]

// Russian

export const russianDetails = [
    {
        fieldName: "Валюта",
        fieldId: "Currency",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1696583821236",
        dropDown: [
            { fieldId: "$", fieldValue: "$" },
            { fieldId: "€", fieldValue: "€" },
            { fieldId: "UZS", fieldValue: "UZS" }
        ],    
        _id: "641c0c2e690ed315f0e1f765"
    },
    {
        fieldName: "Расположение этажа",
        fieldId: "Floor Location",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560086649",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" },
            { fieldId: "11", fieldValue: "11" },
            { fieldId: "12", fieldValue: "12" },
            { fieldId: "13", fieldValue: "13" },
            { fieldId: "14", fieldValue: "14" },
            { fieldId: "15", fieldValue: "15" },
            { fieldId: "16", fieldValue: "16" },
            { fieldId: "17", fieldValue: "17" },
            { fieldId: "18", fieldValue: "18" },
            { fieldId: "19", fieldValue: "19" },
            { fieldId: "20", fieldValue: "20" }
        ],
        _id: "641c0c2e690ed315f0e1f766"
    },
    {
        fieldName: "От кого",
        fieldId: "From whom",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690183818545",
        dropDown: [
            { fieldId: "Owner", fieldValue: "Владелец" },
            { fieldId: "Real estate agency", fieldValue: "Агентство Недвижимости" }
        ],
        _id: "641c0c2e690ed315f0e1f767"
    },
    {
        fieldName: "Назначение",
        fieldId: "Purpose",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560314529",
        dropDown: [
            { fieldId: "Sale", fieldValue: "Распродажа" },
            { fieldId: "Rent", fieldValue: "Арендовать" },
            { fieldId: "Daily Rent", fieldValue: "Посуточная аренда" }
        ],        
        _id: "641c0c2e690ed315f0e1f768"
    },
    {
        fieldName: "Салон",
        fieldId: "Saloon",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184157157",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" }
        ],
        _id: "641c0c2e690ed315f0e1f769"
    },
    {
        fieldName: "Спальни",
        fieldId: "Bedrooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560433432",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" }
        ],  
        _id: "641c0c2e690ed315f0e1f76a"
    },
    {
        fieldName: "Ванные комнаты",
        fieldId: "Bathrooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560508480",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" }
        ],
        _id: "641c0c2e690ed315f0e1f76b"
    },
    {
        fieldName: "Меблированная",
        fieldId: "Furnished",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184245902",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],        
        _id: "641c0c2e690ed315f0e1f76c"
    },
    {
        fieldName: "Балкон",
        fieldId: "Balcony",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560577225",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" }
        ],
        _id: "641c0c2e690ed315f0e1f76d"
    },
    {
        fieldName: "Видеозвонок",
        fieldId: "Video Call",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560625960",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],        
        _id: "641c0c2e690ed315f0e1f76e"
    },
    {
        fieldName: "Статус использования",
        fieldId: "Usage Status",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184463460",
        dropDown: [
            { fieldId: "Rented", fieldValue: "Арендованный" },
            { fieldId: "Empty", fieldValue: "Пустой" }
        ],        
        _id: "641c0c2e690ed315f0e1f76f"
    },
    {
        fieldName: "Фасад",
        fieldId: "Facade",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184541392",
        dropDown: [
            { fieldId: "North", fieldValue: "Север" },
            { fieldId: "South", fieldValue: "Юг" },
            { fieldId: "East", fieldValue: "Восток" },
            { fieldId: "West", fieldValue: "Запад" }
        ],        
        _id: "641c0c2e690ed315f0e1f770"
    },
    {
        fieldName: "Внутренний комплекс",
        fieldId: "Inside Complex",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184949009",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],        
        _id: "641c0c2e690ed315f0e1f771"
    },
    {
        fieldName: "Система отопления",
        fieldId: "Heating System",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560817795",
        dropDown: [
            { fieldId: "Stove", fieldValue: "Плита" },
            { fieldId: "Natural Gas Stove", fieldValue: "Плита на природном газе" },
            { fieldId: "Room Heater", fieldValue: "Комнатный обогреватель" },
            { fieldId: "Central Heating", fieldValue: "Центральное отопление" },
            { fieldId: "Floor Heating", fieldValue: "Напольное отопление" },
            { fieldId: "Air conditioning ", fieldValue: "Кондиционер" },
            { fieldId: "Fan Coil Unit", fieldValue: "Блок вентиляторного доводчика" },
            { fieldId: "Solar Energy", fieldValue: "Солнечная энергия" },
            { fieldId: "Electrical Radiator", fieldValue: "Электрический радиатор" },
            { fieldId: "Fireplace", fieldValue: "Камин" },
            { fieldId: "Geothermal", fieldValue: "Геотермальная" }
        ],        
        _id: "641c0c2e690ed315f0e1f772"
    },
    {
        fieldName: "Возраст здания",
        fieldId: "Building Age",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1690185207726",
        dropDown: [
            { fieldId: "New", fieldValue: "Новый" },
            { fieldId: "1 Years", fieldValue: "1 год" },
            { fieldId: "2 Years", fieldValue: "2 года" },
            { fieldId: "3 Years", fieldValue: "3 года" },
            { fieldId: "4 Years", fieldValue: "4 года" },
            { fieldId: "5 Years", fieldValue: "5 года" },
            { fieldId: "6 Years", fieldValue: "6 года" },
            { fieldId: "7 Years", fieldValue: "7 года" },
            { fieldId: "8 Years", fieldValue: "8 года" },
            { fieldId: "9 Years", fieldValue: "9 года" },
            { fieldId: "10 Years", fieldValue: "10 года" },
            { fieldId: "10+ Years", fieldValue: "10+ года" }
        ],        
        _id: "641c0c2e690ed315f0e1f773"
    },
    {
        fieldName: "Этажи зданий",
        fieldId: "Building Floors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688561034038",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" }
        ],
        _id: "641c0c2e690ed315f0e1f774"
    },
    {
        fieldName: "Статус правоустанавливающего документа",
        fieldId: "Title Deed Status",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690185351107",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],        
        _id: "641c0c2e690ed315f0e1f775"
    },
    {
        fieldName: "Право на ипотеку",
        fieldId: "Eligible for Mortgage",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690186029113",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ], 
        _id: "641c0c2e690ed315f0e1f776"
    },
    {
        fieldName: "Бартер / обмен",
        fieldId: "Barter / Exchange",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690187050272",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ], 
        _id: "641c0c2e690ed315f0e1f777"
    },
    {
        fieldName: "Количество комнат",
        fieldId: "Number of Rooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628428763",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" },
            { fieldId: "20+", fieldValue: "20+" },
            { fieldId: "30+", fieldValue: "30+" },
            { fieldId: "40+", fieldValue: "40+" },
            { fieldId: "50+", fieldValue: "50+" }
        ],
        _id: "641c0c2e690ed315f0e1f778"
    },
    {
        fieldName: "Внутри промышленной зоны",
        fieldId: "Inside Industrial Area",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628539299",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ],
        _id: "641c0c2e690ed315f0e1f779"
    },
    {
        fieldName: "Кухня",
        fieldId: "Kitchen",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628579364",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ], 
        _id: "641c0c2e690ed315f0e1f77a"
    },
    {
        fieldName: "Конференц-залы",
        fieldId: "Meeting Rooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628642343",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ], 
        _id: "641c0c2e690ed315f0e1f77b"
    },
    {
        fieldName: "Парковка",
        fieldId: "Parking",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624292370",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ], 
        _id: "641c0c2e690ed315f0e1f77c"
    },
    {
        fieldName: "Вместимость",
        fieldId: "Capacity",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628698590",
        dropDown: [
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" }
        ],
        _id: "641c0c2e690ed315f0e1f77d"
    },
    {
        fieldName: "Количество магазинов",
        fieldId: "Number of Shops",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628738275",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" },
            { fieldId: "20+", fieldValue: "20+" },
            { fieldId: "30+", fieldValue: "30+" }
        ],
        _id: "641c0c2e690ed315f0e1f77e"
    },
    {
        fieldName: "Рестораны",
        fieldId: "Restaurants",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624354497",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ], 
        _id: "641c0c2e690ed315f0e1f77f"
    },
    {
        fieldName: "Бары",
        fieldId: "Bars",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628777818",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" }
        ],
        _id: "641c0c2e690ed315f0e1f780"
    },
    {
        fieldName: "Плавательный бассейн",
        fieldId: "Swimming pool",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624423937",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Да" },
            { fieldId: "No", fieldValue: "Нет" }
        ], 
        _id: "641c0c2e690ed315f0e1f781"
    },
    {
        fieldName: "Количество этажей",
        fieldId: "Number of floors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628818781",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" }
        ],
        _id: "641c0c2e690ed315f0e1f782"
    }
]

export const russianInternalFeatures = [
    {
        fieldName: "Пожарная сигнализация",
        fieldId: "Fire Alarm",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630138779",
        _id: "641c0c2e690ed315f0e1f783"
    },
    {
        fieldName: "Открытая кухня",
        fieldId: "Open kitchen",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630180257",
        _id: "641c0c2e690ed315f0e1f784"
    },
    {
        fieldName: "Стиральная машина",
        fieldId: "Washing Machine",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630244312",
        _id: "641c0c2e690ed315f0e1f785"
    },
    {
        fieldName: "Душевая кабина",
        fieldId: "Shower cabin",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630349692",
        _id: "641c0c2e690ed315f0e1f786"
    },
    {
        fieldName: "Оптоволоконный интернет",
        fieldId: "Fibre optics Internet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630380677",
        _id: "641c0c2e690ed315f0e1f787"
    },
    {
        fieldName: "Водонагреватель",
        fieldId: "Water heater",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630445829",
        _id: "641c0c2e690ed315f0e1f788"
    },
    {
        fieldName: "Встроенная печь",
        fieldId: "Buit-in oven",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630472293",
        _id: "641c0c2e690ed315f0e1f789"
    },
    {
        fieldName: "Обои",
        fieldId: "Wallpaper",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630502909",
        _id: "641c0c2e690ed315f0e1f78a"
    },
    {
        fieldName: "Гардеробная",
        fieldId: "Dressing room",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630524910",
        _id: "641c0c2e690ed315f0e1f78b"
    },
    {
        fieldName: "Кухонный газ",
        fieldId: "Kithchen Gas",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630555364",
        _id: "641c0c2e690ed315f0e1f78c"
    },
    {
        fieldName: "Площадь нетто",
        fieldId: "Area Net",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630588177",
        _id: "641c0c2e690ed315f0e1f78d"
    },
    {
        fieldName: "Камин",
        fieldId: "Fireplace",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630619563",
        _id: "641c0c2e690ed315f0e1f78e"
    },
    {
        fieldName: "Wi-Fi",
        fieldId: "Wi-Fi",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630646540",
        _id: "641c0c2e690ed315f0e1f78f"
    },
    {
        fieldName: "Умный дом",
        fieldId: "Smart House",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630682136",
        _id: "641c0c2e690ed315f0e1f790"
    },
    {
        fieldName: "Система сигнализации от воров",
        fieldId: "Thief Alarm system",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630743790",
        _id: "641c0c2e690ed315f0e1f791"
    },
    {
        fieldName: "Посудомоечная машина",
        fieldId: "Dishwasher",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630776486",
        _id: "641c0c2e690ed315f0e1f792"
    },
    {
        fieldName: "Прачечная",
        fieldId: "Laundry room",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630805579",
        _id: "641c0c2e690ed315f0e1f793"
    },
    {
        fieldName: "Главная ванная комната",
        fieldId: "Master Bathroom",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630838849",
        _id: "641c0c2e690ed315f0e1f794"
    },
    {
        fieldName: "Шкаф",
        fieldId: "Closet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630922248",
        _id: "641c0c2e690ed315f0e1f795"
    },
    {
        fieldName: "Кондиционер",
        fieldId: "Air conditioning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630966301",
        _id: "641c0c2e690ed315f0e1f796"
    },
    {
        fieldName: "Мебель",
        fieldId: "Furniture",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630997947",
        _id: "641c0c2e690ed315f0e1f797"
    },
    {
        fieldName: "Терас",
        fieldId: "Terace",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631049157",
        _id: "641c0c2e690ed315f0e1f798"
    },
    {
        fieldName: "Распознавание лиц и отпечатки пальцев",
        fieldId: "Face Recognition & Fingerprint",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631074506",
        _id: "641c0c2e690ed315f0e1f799"
    },
    {
        fieldName: "Барбекю",
        fieldId: "Barbecue",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631380504",
        _id: "641c0c2e690ed315f0e1f79a"
    },
    {
        fieldName: "Холодильник",
        fieldId: "Fridge",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631154564",
        _id: "641c0c2e690ed315f0e1f79b"
    },
    {
        fieldName: "Украсть",
        fieldId: "Steel door",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631220062",
        _id: "641c0c2e690ed315f0e1f79c"
    },
    {
        fieldName: "Духовка",
        fieldId: "Oven",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631253138",
        _id: "641c0c2e690ed315f0e1f79d"
    },
    {
        fieldName: "Джакузи",
        fieldId: "Jacuzzi",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631275553",
        _id: "641c0c2e690ed315f0e1f79e"
    },
    {
        fieldName: "Ванна",
        fieldId: "Bathub",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631317104",
        _id: "641c0c2e690ed315f0e1f79f"
    },
    {
        fieldName: "Евродекор",
        fieldId: "Euro decor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631352621",
        _id: "641c0c2e690ed315f0e1f7a0"
    }
]

export const russianExternalFeatures = [
    {
        fieldName: "Система камер",
        fieldId: "Camera system",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625379437",
        _id: "641c0c2e690ed315f0e1f7a1"
    },
    {
        fieldName: "Крытый гараж",
        fieldId: "Covered Garage",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625420758",
        _id: "641c0c2e690ed315f0e1f7a3"
    },
    {
        fieldName: "Кабельное телевидение",
        fieldId: "Cable TV",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688629885055",
        _id: "641c0c2e690ed315f0e1f7a4"
    },
    {
        fieldName: "Подъемник",
        fieldId: "Lift",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625589448",
        _id: "641c0c2e690ed315f0e1f7a5"
    },
    {
        fieldName: "Теплоизоляция",
        fieldId: "Thermal Insultation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625629237",
        _id: "641c0c2e690ed315f0e1f7a6"
    },
    {
        fieldName: "Частный бассейн",
        fieldId: "Private Pool",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625668725",
        _id: "641c0c2e690ed315f0e1f7a7"
    },
    {
        fieldName: "Звуковое оскорбление",
        fieldId: "Sound insultation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625766687",
        _id: "641c0c2e690ed315f0e1f7a8"
    },
    {
        fieldName: "Спутник",
        fieldId: "Satellite",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625804116",
        _id: "641c0c2e690ed315f0e1f7a9"
    },
    {
        fieldName: "Бассейн (крытый)",
        fieldId: "Swimming Pool (Indoor)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688629921792",
        _id: "641c0c2e690ed315f0e1f7aa"
    },
    {
        fieldName: "Бассейн (открытый)",
        fieldId: "Swimming Pool (Outdoor)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625845481",
        _id: "641c0c2e690ed315f0e1f7ab"
    },
    {
        fieldName: "Генератор",
        fieldId: "Generator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625918759",
        _id: "641c0c2e690ed315f0e1f7ac"
    },
    {
        fieldName: "Швейцар",
        fieldId: "Doorman",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625947861",
        _id: "641c0c2e690ed315f0e1f7ad"
    },
    {
        fieldName: "Игровая площадка",
        fieldId: "Playground",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626018598",
        _id: "641c0c2e690ed315f0e1f7ae"
    },
    {
        fieldName: "Спортивное поле",
        fieldId: "Sports Field",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626057931",
        _id: "641c0c2e690ed315f0e1f7af"
    },
    {
        fieldName: "Пожарная лестница",
        fieldId: "Fire escape",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626133236",
        _id: "641c0c2e690ed315f0e1f7b0"
    },
    {
        fieldName: "Сауна",
        fieldId: "Sauna",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626171963",
        _id: "641c0c2e690ed315f0e1f7b1"
    },
    {
        fieldName: "Резервуар для воды",
        fieldId: "Water tank",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626243162",
        _id: "641c0c2e690ed315f0e1f7b2"
    }
]

export const russianEnvironment = [
    {
        fieldName: "Близлежащий парк",
        fieldId: "Nearby Park",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626667209",
        _id: "641c0c2e690ed315f0e1f7b3"
    },
    {
        fieldName: "Торговый центр",
        fieldId: "Shopping Mall",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626709275",
        _id: "641c0c2e690ed315f0e1f7b4"
    },
    {
        fieldName: "Мечеть",
        fieldId: "Mosque",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626738271",
        _id: "641c0c2e690ed315f0e1f7b5"
    },
    {
        fieldName: "Тренажерный зал",
        fieldId: "Gym",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626765580",
        _id: "641c0c2e690ed315f0e1f7b6"
    },
    {
        fieldName: "Парк",
        fieldId: "Park",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626800015",
        _id: "641c0c2e690ed315f0e1f7b7"
    },
    {
        fieldName: "Школа",
        fieldId: "School",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626828647",
        _id: "641c0c2e690ed315f0e1f7b8"
    },
    {
        fieldName: "Университет",
        fieldId: "University",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626859153",
        _id: "641c0c2e690ed315f0e1f7b9"
    },
    {
        fieldName: "Политическая станция",
        fieldId: "Policy Station",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626925597",
        _id: "641c0c2e690ed315f0e1f7bb"
    }
]

export const russianAccessToTransportation = [
    {
        fieldName: "Метро",
        fieldId: "Metro",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627218084",
        _id: "641c0c2e690ed315f0e1f7bc"
    },
    {
        fieldName: "Микроавтобус",
        fieldId: "Minibus",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627487384",
        _id: "641c0c2e690ed315f0e1f7bd"
    },
    {
        fieldName: "Шоссе",
        fieldId: "Highway",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627747663",
        _id: "641c0c2e690ed315f0e1f7be"
    },
    {
        fieldName: "Автобусная остановка",
        fieldId: "Bus Stop",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627779242",
        _id: "641c0c2e690ed315f0e1f7bf"
    },
    {
        fieldName: "Железная дорога",
        fieldId: "Railway",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627840835",
        _id: "641c0c2e690ed315f0e1f7c0"
    },
    {
        fieldName: "Аэропорт",
        fieldId: "Airport",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627879748",
        _id: "641c0c2e690ed315f0e1f7c1"
    },
    {
        fieldName: "Канатная дорога",
        fieldId: "Cable Car",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627927715",
        _id: "641c0c2e690ed315f0e1f7c2"
    }
]

export const russianView = [
    {
        fieldName: "Город",
        fieldId: "City",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635771770",
        _id: "641c0c2e690ed315f0e1f7c3"
    },
    {
        fieldName: "Внутри комплекса",
        fieldId: "Inside the Complex",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635944990",
        _id: "641c0c2e690ed315f0e1f7c4"
    },
    {
        fieldName: "Улица",
        fieldId: "Street",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635973705",
        _id: "641c0c2e690ed315f0e1f7c5"
    },
    {
        fieldName: "Главная дорога",
        fieldId: "Main Road",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636264195",
        _id: "641c0c2e690ed315f0e1f7c6"
    },
    {
        fieldName: "Море",
        fieldId: "Sea",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636301920",
        _id: "641c0c2e690ed315f0e1f7c7"
    },
    {
        fieldName: "Река",
        fieldId: "River",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636331827",
        _id: "641c0c2e690ed315f0e1f7c8"
    },
    {
        fieldName: "Озеро",
        fieldId: "Lake",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636383484",
        _id: "641c0c2e690ed315f0e1f7c9"
    },
    {
        fieldName: "Лес",
        fieldId: "Forest",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636413169",
        _id: "641c0c2e690ed315f0e1f7ca"
    },
    {
        fieldName: "Пустыня",
        fieldId: "Desert",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636448339",
        _id: "641c0c2e690ed315f0e1f7cb"
    },
    {
        fieldName: "Панорама",
        fieldId: "Panorama",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636476982",
        _id: "641c0c2e690ed315f0e1f7cc"
    }
]

export const russianDisabledFacilities = [
    {
        fieldName: "Парковка",
        fieldId: "Car Parking",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627986561",
        _id: "641c0c2e690ed315f0e1f7cd"
    },
    {
        fieldName: "Лестницы",
        fieldId: "Stairs",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628016119",
        _id: "641c0c2e690ed315f0e1f7ce"
    },
    {
        fieldName: "Подъемник",
        fieldId: "Lift",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628050777",
        _id: "641c0c2e690ed315f0e1f7cf"
    },
    {
        fieldName: "Место для зарядки электронных транспортных средств",
        fieldId: "Charging place for Electronical vehicles",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628084392",
        _id: "641c0c2e690ed315f0e1f7d0"
    },
    {
        fieldName: "Туалет",
        fieldId: "Toilet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628112730",
        _id: "641c0c2e690ed315f0e1f7d1"
    }
]


// Uzbek

export const uzbekDetails = [
    {
        fieldName: "Valyuta",
        fieldId: "Currency",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1696583821236",
        dropDown: [
            { fieldId: "$", fieldValue: "$" },
            { fieldId: "€", fieldValue: "€" },
            { fieldId: "UZS", fieldValue: "UZS" }
        ],
        _id: "641c0c2e690ed315f0e1f8e6"
    },
    {
        fieldName: "Kimdan",
        fieldId: "From whom",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690183818545",
        dropDown: [
            { fieldId: "Owner", fieldValue: "Egasi" },
            { fieldId: "Real estate agency", fieldValue: "Ko'chmas mulk agentligi" }
        ],        
        _id: "641c0c2e690ed315f0e1f8e7"
    },
    {
        fieldName: "Maqsad",
        fieldId: "Purpose",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690183200323",
        dropDown: [
            { fieldId: "Sale", fieldValue: "Sotish" },
            { fieldId: "Rent", fieldValue: "Ijara" },
            { fieldId: "Daily Rent", fieldValue: "Kunlik ijara" }
        ],        
        _id: "641c0c2e690ed315f0e1f8e8"
    },
    {
        fieldName: "Salon",
        fieldId: "Saloon",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184157157",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" }
        ],
        _id: "641c0c2e690ed315f0e1f8e9"
    },
    {
        fieldName: "Yotoq xonalari",
        fieldId: "Bedrooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560433432",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" }
        ], 
        _id: "641c0c2e690ed315f0e1f8ea"
    },
    {
        fieldName: "Hammomlar",
        fieldId: "Bathrooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560508480",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" }
        ],
        _id: "641c0c2e690ed315f0e1f8eb"
    },
    {
        fieldName: "Mebel bilan jihozlangan",
        fieldId: "Furnished",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184245902",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],        
        _id: "641c0c2e690ed315f0e1f8ec"
    },
    {
        fieldName: "Balkon",
        fieldId: "Balcony",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560577225",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" }
        ],
        _id: "641c0c2e690ed315f0e1f8ed"
    },
    {
        fieldName: "Video qo'ng'iroq",
        fieldId: "Video Call",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560625960",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],  
        _id: "641c0c2e690ed315f0e1f8ee"
    },
    {
        fieldName: "Foydalanish holati",
        fieldId: "Usage Status",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184463460",
        dropDown: [
            { fieldId: "Rented", fieldValue: "Ijaraga olingan" },
            { fieldId: "Empty", fieldValue: "Bo'sh" }
        ],        
        _id: "641c0c2e690ed315f0e1f8ef"
    },
    {
        fieldName: "Fasad",
        fieldId: "Facade",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184541392",
        dropDown: [
            { fieldId: "North", fieldValue: "Shimoliy" },
            { fieldId: "South", fieldValue: "janubiy" },
            { fieldId: "East", fieldValue: "Sharq" },
            { fieldId: "West", fieldValue: "G'arbiy" }
        ],        
        _id: "641c0c2e690ed315f0e1f8f0"
    },
    {
        fieldName: "Ichki majmua",
        fieldId: "Inside Complex",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690184949009",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8f1"
    },
    {
        fieldName: "Isitish tizimi",
        fieldId: "Heating System",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560817795",
        dropDown: [
            { fieldId: "Stove", fieldValue: "Pechka" },
            { fieldId: "Natural Gas Stove", fieldValue: "Tabiiy gaz pechkasi" },
            { fieldId: "Room Heater", fieldValue: "Xona isitgichi" },
            { fieldId: "Central Heating", fieldValue: "Markaziy isitish" },
            { fieldId: "Floor Heating", fieldValue: "Yerdan isitish" },
            { fieldId: "Air conditioning", fieldValue: "Havo sovutish " },
            { fieldId: "Fan Coil Unit", fieldValue: "Fan coil birligi" },
            { fieldId: "Solar Energy", fieldValue: "Quyosh energiyasi" },
            { fieldId: "Electrical Radiator", fieldValue: "Elektr radiatori" },
            { fieldId: "Fireplace", fieldValue: "Kamin" },
            { fieldId: "Geothermal", fieldValue: "Yer mavzusi" }
        ],        
        _id: "641c0c2e690ed315f0e1f8f2"
    },
    {
        fieldName: "Qurilish yoshi",
        fieldId: "Building Age",
        imageUrl: "https://egasi-storage.s3.amazonaws.com/1690185207726",
        dropDown: [
            { fieldId: "New", fieldValue: "Yangi" },
            { fieldId: "1 Years", fieldValue: "1 yil" },
            { fieldId: "2 Years", fieldValue: "2 yil" },
            { fieldId: "3 Years", fieldValue: "3 yil" },
            { fieldId: "4 Years", fieldValue: "4 yil" },
            { fieldId: "5 Years", fieldValue: "5 yil" },
            { fieldId: "6 Years", fieldValue: "6  yil" },
            { fieldId: "7 Years", fieldValue: "7 yil" },
            { fieldId: "8 Years", fieldValue: "8 yil" },
            { fieldId: "9 Years", fieldValue: "9 yil" },
            { fieldId: "10 Years", fieldValue: "10 yil" },
            { fieldId: "10+ Years", fieldValue: "10+ yil" }
        ],        
        _id: "641c0c2e690ed315f0e1f8f3"
    },
    {
        fieldName: "Bino qavatlari",
        fieldId: "Building Floors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688561034038",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" }
        ],
        _id: "641c0c2e690ed315f0e1f8f4"
    },
    {
        fieldName: "Hujjatning holati",
        fieldId: "Title Deed Status",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690185351107",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8f5"
    },
    {
        fieldName: "Ipoteka uchun mos",
        fieldId: "Eligible for Mortgage",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690186029113",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8f6"
    },
    {
        fieldName: "Barter / almashish",
        fieldId: "Barter / Exchange",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1690187050272",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8f7"
    },
    {
        fieldName: "Xonalar soni",
        fieldId: "Number of Rooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628428763",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" },
            { fieldId: "20+", fieldValue: "20+" },
            { fieldId: "30+", fieldValue: "30+" },
            { fieldId: "40+", fieldValue: "40+" },
            { fieldId: "50+", fieldValue: "50+" }
        ],
        _id: "641c0c2e690ed315f0e1f8f8"
    },
    {
        fieldName: "Sanoat hududi ichida",
        fieldId: "Inside Industrial Area",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628539299",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8f9"
    },
    {
        fieldName: "Oshxona",
        fieldId: "Kitchen",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628579364",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8fa"
    },
    {
        fieldName: "Uchrashuv xonalari",
        fieldId: "Meeting Rooms",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628642343",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8fb"
    },
    {
        fieldName: "Avtoturargoh",
        fieldId: "Parking",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624292370",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8fc"
    },
    {
        fieldName: "Imkoniyat",
        fieldId: "Capacity",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628698590",
        dropDown: [
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" }
        ],
        _id: "641c0c2e690ed315f0e1f8fd"
    },
    {
        fieldName: "Do'konlar soni",
        fieldId: "Number of Shops",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628738275",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10+", fieldValue: "10+" },
            { fieldId: "20+", fieldValue: "20+" },
            { fieldId: "30+", fieldValue: "30+" }
        ],
        _id: "641c0c2e690ed315f0e1f8fe"
    },
    {
        fieldName: "Qavat joylashuvi",
        fieldId: "Floor Location",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688560086649",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" },
            { fieldId: "5", fieldValue: "5" },
            { fieldId: "6", fieldValue: "6" },
            { fieldId: "7", fieldValue: "7" },
            { fieldId: "8", fieldValue: "8" },
            { fieldId: "9", fieldValue: "9" },
            { fieldId: "10", fieldValue: "10" },
            { fieldId: "11", fieldValue: "11" },
            { fieldId: "12", fieldValue: "12" },
            { fieldId: "13", fieldValue: "13" },
            { fieldId: "14", fieldValue: "14" },
            { fieldId: "15", fieldValue: "15" },
            { fieldId: "16", fieldValue: "16" },
            { fieldId: "17", fieldValue: "17" },
            { fieldId: "18", fieldValue: "18" },
            { fieldId: "19", fieldValue: "19" },
            { fieldId: "20", fieldValue: "20" }
        ],
        
        
        _id: "641c0c2e690ed315f0e1f902"
    },
    {
        fieldName: "Restoranlar",
        fieldId: "Restaurants",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624354497",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f8ff"
    },
    {
        fieldName: "Barlar",
        fieldId: "Bars",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628777818",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" }
        ],
        _id: "641c0c2e690ed315f0e1f900"
    },
    {
        fieldName: "Suzish havzasi",
        fieldId: "Swimming pool",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688624423937",
        dropDown: [
            { fieldId: "Yes", fieldValue: "Ha" },
            { fieldId: "No", fieldValue: "Yo'q" }
        ],
        _id: "641c0c2e690ed315f0e1f901"
    },
    {
        fieldName: "Qavatlar soni",
        fieldId: "Number of floors",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628818781",
        dropDown: [
            { fieldId: "1", fieldValue: "1" },
            { fieldId: "2", fieldValue: "2" },
            { fieldId: "3", fieldValue: "3" },
            { fieldId: "4", fieldValue: "4" }
        ],
        _id: "641c0c2e690ed315f0e1f902"
    },
]

export const uzbekInternalFeatures = [
    {
        fieldName: "Yong'in signalizatsiyasi",
        fieldId: "Fire Alarm",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630138779",
        _id: "641c0c2e690ed315f0e1f903"
    },
    {
        fieldName: "Ochiq oshxona",
        fieldId: "Open kitchen",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630180257",
        _id: "641c0c2e690ed315f0e1f904"
    },
    {
        fieldName: "Kir yuvish mashinasi",
        fieldId: "Washing Machine",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630244312",
        _id: "641c0c2e690ed315f0e1f905"
    },
    {
        fieldName: "Dush kabinasi",
        fieldId: "Shower cabin",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630349692",
        _id: "641c0c2e690ed315f0e1f906"
    },
    {
        fieldName: "Optik tolali Internet",
        fieldId: "Fibre optics Internet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630380677",
        _id: "641c0c2e690ed315f0e1f907"
    },
    {
        fieldName: "Suv isitgichi",
        fieldId: "Water heater",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630445829",
        _id: "641c0c2e690ed315f0e1f908"
    },
    {
        fieldName: "O'rnatilgan pech",
        fieldId: "Buit-in oven",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630472293",
        _id: "641c0c2e690ed315f0e1f909"
    },
    {
        fieldName: "Fon rasmi",
        fieldId: "Wallpaper",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630502909",
        _id: "641c0c2e690ed315f0e1f90a"
    },
    {
        fieldName: "Kiyinish xonasi",
        fieldId: "Dressing room",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630524910",
        _id: "641c0c2e690ed315f0e1f90b"
    },
    {
        fieldName: "Oshxona gazi",
        fieldId: "Kithchen Gas",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630555364",
        _id: "641c0c2e690ed315f0e1f90c"
    },
    {
        fieldName: "Hudud tarmog'i",
        fieldId: "Area Net",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630588177",
        _id: "641c0c2e690ed315f0e1f90d"
    },
    {
        fieldName: "Kamin",
        fieldId: "Fireplace",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630619563",
        _id: "641c0c2e690ed315f0e1f90e"
    },
    {
        fieldName: "Wi-fi",
        fieldId: "Wi-Fi",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630646540",
        _id: "641c0c2e690ed315f0e1f90f"
    },
    {
        fieldName: "Aqlli uy",
        fieldId: "Smart House",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630682136",
        _id: "641c0c2e690ed315f0e1f910"
    },
    {
        fieldName: "O'g'ri signal tizimi",
        fieldId: "Thief Alarm system",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630743790",
        _id: "641c0c2e690ed315f0e1f911"
    },
    {
        fieldName: "Idish yuvish mashinasi",
        fieldId: "Dishwasher",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630776486",
        _id: "641c0c2e690ed315f0e1f912"
    },
    {
        fieldName: "Kir yuvish xonasi",
        fieldId: "Laundry room",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630805579",
        _id: "641c0c2e690ed315f0e1f913"
    },
    {
        fieldName: "Asosiy hammom",
        fieldId: "Master Bathroom",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630838849",
        _id: "641c0c2e690ed315f0e1f914"
    },
    {
        fieldName: "Shkaf",
        fieldId: "Closet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630922248",
        _id: "641c0c2e690ed315f0e1f915"
    },
    {
        fieldName: "Havo sovutish",
        fieldId: "Air conditioning",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630966301",
        _id: "641c0c2e690ed315f0e1f916"
    },
    {
        fieldName: "Mebel",
        fieldId: "Furniture",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688630997947",
        _id: "641c0c2e690ed315f0e1f917"
    },
    {
        fieldName: "Teras",
        fieldId: "Terace",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631049157",
        _id: "641c0c2e690ed315f0e1f918"
    },
    {
        fieldName: "Yuzni tanish va barmoq izi",
        fieldId: "Face Recognition & Fingerprint",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631074506",
        _id: "641c0c2e690ed315f0e1f919"
    },
    {
        fieldName: "Barbekyu",
        fieldId: "Barbecue",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631380504",
        _id: "641c0c2e690ed315f0e1f91a"
    },
    {
        fieldName: "Muzlatgich",
        fieldId: "Fridge",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631154564",
        _id: "641c0c2e690ed315f0e1f91b"
    },
    {
        fieldName: "Temir eshik",
        fieldId: "Steel door",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631220062",
        _id: "641c0c2e690ed315f0e1f91c"
    },
    {
        fieldName: "Pech",
        fieldId: "Oven",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631253138",
        _id: "641c0c2e690ed315f0e1f91d"
    },
    {
        fieldName: "Jakuzi",
        fieldId: "Jacuzzi",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631275553",
        _id: "641c0c2e690ed315f0e1f91e"
    },
    {
        fieldName: "Vanna",
        fieldId: "Bathub",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631317104",
        _id: "641c0c2e690ed315f0e1f91f"
    },
    {
        fieldName: "Evro dekorasi",
        fieldId: "Euro decor",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688631352621",
        _id: "641c0c2e690ed315f0e1f920"
    }
]

export const uzbekExternalFeatures = [
    {
        fieldName: "Kamera tizimi",
        fieldId: "Camera system",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625379437",
        _id: "641c0c2e690ed315f0e1f921"
    },
    {
        fieldName: "Yopiq garaj",
        fieldId: "Covered Garage",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625420758",
        _id: "641c0c2e690ed315f0e1f923"
    },
    {
        fieldName: "Kabel televideniesi",
        fieldId: "Cable TV",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688629885055",
        _id: "641c0c2e690ed315f0e1f924"
    },
    {
        fieldName: "Lift",
        fieldId: "Lift",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625589448",
        _id: "641c0c2e690ed315f0e1f925"
    },
    {
        fieldName: "Issiqlik izolyatsiyasi",
        fieldId: "Thermal Insultation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625629237",
        _id: "641c0c2e690ed315f0e1f926"
    },
    {
        fieldName: "Shaxsiy basseyn",
        fieldId: "Private Pool",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625668725",
        _id: "641c0c2e690ed315f0e1f927"
    },
    {
        fieldName: "Ovoz izolyatsiyasi",
        fieldId: "Sound insultation",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625766687",
        _id: "641c0c2e690ed315f0e1f928"
    },
    {
        fieldName: "Sun'iy yo'ldosh",
        fieldId: "Satellite",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625804116",
        _id: "641c0c2e690ed315f0e1f929"
    },
    {
        fieldName: "Sun'iy yo'ldosh",
        fieldId: "Swimming Pool (Indoor)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625804116",
        _id: "641c0c2e690ed315f0e1f92a"
    },
    {
        fieldName: "Hovuz (ochiq havoda)",
        fieldId: "Swimming Pool (Outdoor)",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625845481",
        _id: "641c0c2e690ed315f0e1f92b"
    },
    {
        fieldName: "Generator",
        fieldId: "Generator",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625918759",
        _id: "641c0c2e690ed315f0e1f92c"
    },
    {
        fieldName: "Eshikchi",
        fieldId: "Doorman",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688625947861",
        _id: "641c0c2e690ed315f0e1f92d"
    },
    {
        fieldName: "O'yin maydonchasi",
        fieldId: "Playground",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626018598",
        _id: "641c0c2e690ed315f0e1f92e"
    },
    {
        fieldName: "Sport maydoni",
        fieldId: "Sports Field",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626057931",
        _id: "641c0c2e690ed315f0e1f92f"
    },
    {
        fieldName: "Yong'indan qochish",
        fieldId: "Fire escape",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626133236",
        _id: "641c0c2e690ed315f0e1f930"
    },
    {
        fieldName: "Sauna",
        fieldId: "Sauna",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626171963",
        _id: "641c0c2e690ed315f0e1f931"
    },
    {
        fieldName: "Suv idishi",
        fieldId: "Water tank",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626243162",
        _id: "641c0c2e690ed315f0e1f932"
    }
]

export const uzbekEnvironment = [
    {
        fieldName: "Yaqin atrofdagi park",
        fieldId: "Nearby Park",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626667209",
        _id: "641c0c2e690ed315f0e1f933"
    },
    {
        fieldName: "Savdo markazi",
        fieldId: "Shopping Mall",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626709275",
        _id: "641c0c2e690ed315f0e1f934"
    },
    {
        fieldName: "Masjid",
        fieldId: "Mosque",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626738271",
        _id: "641c0c2e690ed315f0e1f935"
    },
    {
        fieldName: "sportzal",
        fieldId: "Gym",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626765580",
        _id: "641c0c2e690ed315f0e1f936"
    },
    {
        fieldName: "Park",
        fieldId: "Park",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626800015",
        _id: "641c0c2e690ed315f0e1f937"
    },
    {
        fieldName: "Maktab",
        fieldId: "School",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626828647",
        _id: "641c0c2e690ed315f0e1f938"
    },
    {
        fieldName: "Universitet",
        fieldId: "University",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626859153",
        _id: "641c0c2e690ed315f0e1f939"
    },
    {
        fieldName: "Siyosat stantsiyasi",
        fieldId: "Policy Station",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688626925597",
        _id: "641c0c2e690ed315f0e1f93b"
    }
]

export const uzbekAccessToTransportation = [
    {
        fieldName: "Metro",
        fieldId: "Metro",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627218084",
        _id: "641c0c2e690ed315f0e1f93c"
    },
    {
        fieldName: "Mikroavtobus",
        fieldId: "Minibus",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627487384",
        _id: "641c0c2e690ed315f0e1f93d"
    },
    {
        fieldName: "Magistral yo'l",
        fieldId: "Highway",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627747663",
        _id: "641c0c2e690ed315f0e1f93e"
    },
    {
        fieldName: "Avtobus bekati",
        fieldId: "Bus Stop",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627779242",
        _id: "641c0c2e690ed315f0e1f93f"
    },
    {
        fieldName: "Temir yo'l",
        fieldId: "Railway",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627840835",
        _id: "641c0c2e690ed315f0e1f940"
    },
    {
        fieldName: "Aeroport",
        fieldId: "Airport",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627879748",
        _id: "641c0c2e690ed315f0e1f941"
    },
    {
        fieldName: "Kabel Avtomobil",
        fieldId: "Cable Car",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627927715",
        _id: "641c0c2e690ed315f0e1f942"
    }
]

export const uzbekView = [
    {
        fieldName: "Shahar",
        fieldId: "City",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635771770",
        _id: "641c0c2e690ed315f0e1f943"
    },
    {
        fieldName: "Kompleks ichida",
        fieldId: "Inside the Complex",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635944990",
        _id: "641c0c2e690ed315f0e1f944"
    },
    {
        fieldName: "Ko'cha",
        fieldId: "Street",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688635973705",
        _id: "641c0c2e690ed315f0e1f945"
    },
    {
        fieldName: "Asosiy yo'l",
        fieldId: "Main Road",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636264195",
        _id: "641c0c2e690ed315f0e1f946"
    },
    {
        fieldName: "Dengiz",
        fieldId: "Sea",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636301920",
        _id: "641c0c2e690ed315f0e1f947"
    },
    {
        fieldName: "Daryo",
        fieldId: "River",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636331827",
        _id: "641c0c2e690ed315f0e1f948"
    },
    {
        fieldName: "Ko'l",
        fieldId: "Lake",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636383484",
        _id: "641c0c2e690ed315f0e1f949"
    },
    {
        fieldName: "O'rmon",
        fieldId: "Forest",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636413169",
        _id: "641c0c2e690ed315f0e1f94a"
    },
    {
        fieldName: "Cho'l",
        fieldId: "Desert",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636448339",
        _id: "641c0c2e690ed315f0e1f94b"
    },
    {
        fieldName: "Panorama",
        fieldId: "Panorama",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688636476982",
        _id: "641c0c2e690ed315f0e1f94c"
    }
]

export const uzbekDisabledFacilities = [
    {
        fieldName: "Avtoturargoh",
        fieldId: "Car Parking",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688627986561",
        _id: "641c0c2e690ed315f0e1f94d"
    },
    {
        fieldName: "Zinalar",
        fieldId: "Stairs",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628016119",
        _id: "641c0c2e690ed315f0e1f94e"
    },
    {
        fieldName: "Lift",
        fieldId: "Lift",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628050777",
        _id: "641c0c2e690ed315f0e1f94f"
    },
    {
        fieldName: "Elektron avtomobillar uchun zaryadlash joyi",
        fieldId: "Charging place for Electronical vehicles",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628084392",
        _id: "641c0c2e690ed315f0e1f950"
    },
    {
        fieldName: "Hojatxona",
        fieldId: "Toilet",
        imageUrl: "https://egasi-storage.s3.ap-south-1.amazonaws.com/1688628112730",
        _id: "641c0c2e690ed315f0e1f951"
    }
]