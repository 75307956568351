import { englishInternalFeatures, russianInternalFeatures, uzbekInternalFeatures } from "../translation-files/realestate";

export const translateInternalFeatures = async (data, source, target) => {
    try {
        if (source === "en") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = englishInternalFeatures.find(detail => detail.fieldId === dataItem.fieldName);
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };

                    return updatedData;
                }

                return dataItem;
            });

            const russianData = []
            updatedFeatures.map((item) => {
                const matchedData = russianInternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                    };

                    russianData.push(updatedData)
                }
            })

            const uzbekData = []
            updatedFeatures.map((item) => {
                const matchedData = uzbekInternalFeatures.find(detail => detail.fieldId === item.fieldId);

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                    };

                    uzbekData.push(updatedData)
                }
            })

            return {
                englishFeatures: updatedFeatures,
                russianFeatures: russianData,
                uzbekFeatures: uzbekData
            };
        } else if (source === "ru") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = russianInternalFeatures.find(detail =>
                    detail.fieldName.replace(/\s/g, '').toLowerCase() === dataItem.fieldName.replace(/\s/g, '').toLowerCase()
                  );
                  
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };

                    return updatedData;
                }

                return dataItem;
            });

            const englishData = []
            updatedFeatures.map((item) => {
                const matchedData = englishInternalFeatures.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                    };

                    englishData.push(updatedData)
                }
            })

            const uzbekData = []
            updatedFeatures.map((item) => {
                const matchedData = uzbekInternalFeatures.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                    };

                    uzbekData.push(updatedData)
                }
            })

            return {
                englishFeatures: englishData,
                russianFeatures: updatedFeatures,
                uzbekFeatures: uzbekData
            };
        } else if (source === "uz") {
            const updatedFeatures = data.map(dataItem => {
                const matchedDetail = uzbekInternalFeatures.find(detail =>
                    detail.fieldName.replace(/\s/g, '').toLowerCase() === dataItem.fieldName.replace(/\s/g, '').toLowerCase()
                  );
                  
                if (matchedDetail) {
                    const updatedData = {
                        ...dataItem,
                        fieldId: matchedDetail.fieldId
                    };

                    return updatedData;
                }

                return dataItem;
            });

            const englishData = []
            updatedFeatures.map((item) => {
                const matchedData = englishInternalFeatures.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  
                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                    };

                    englishData.push(updatedData)
                }
            })

            const russianData = []
            updatedFeatures.map((item) => {
                const matchedData = russianInternalFeatures.find(detail =>
                    detail.fieldId.replace(/\s/g, '').toLowerCase() === item.fieldId.replace(/\s/g, '').toLowerCase()
                  );
                  

                if (matchedData) {
                    const updatedData = {
                        fieldId: matchedData.fieldId,
                        fieldName: matchedData.fieldName,
                        imageUrl: matchedData.imageUrl,
                    };

                    russianData.push(updatedData)
                }
            })

            return {
                englishFeatures: englishData,
                russianFeatures: russianData,
                uzbekFeatures: updatedFeatures
            };
        }
    } catch (error) {
        return [];
    }

}