import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import AdInformation from "../../images/AdInformation.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";

import Edit from "../../images/Edit.svg";
import review from "../../images/Review.svg";
import { useDispatch } from "react-redux";
import strings from "../../utils/locals/languages";
import Revieworange from "../../images/Revieworange.svg";
import Footer from "../../components/Footer";
import Cookies from "js-cookie";
import { baseURL1 } from "../../utils/baseUrl";
import { capitalizeFirstLetter } from "../../utils/capitalizeLetter";
import apiClient from "../../utils/apiClient";
import { ClipLoader } from "react-spinners";

const VehiclePreview = ({ isEdit }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const vehicleData = location.state.formik;
  const basicInfo = location.state.basicInfo;
  const [videoFile, setVideoFile] = useState("")


  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const goNext = async () => {

    if (!checked) {
      toast.error(`${strings.acceptRules}`)
    } else {
      setIsLoading(true)
      await callApiAndGoNext()

    }


    sessionStorage.setItem('vehicleData', JSON.stringify(vehicleData));

  }
  const callApiAndGoNext = async () => {
    try {
      const response = await apiClient.get('v1/user/check-user')
      setIsLoading(false)
      if (response.data.success === true) {
        if (isEdit) {
          await apiClient.put(`/v1/adds/update-adds/${vehicleData._id}`, vehicleData);
          toast.success(`${strings.adUpdateMsg}`);
          navigate('/')
        } else {
          navigate('/select-package', {
            state: {
              vehicleData
            }
          })
        }
      } else {
        setIsLoading(false)
        toast.error(response.data.message)
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error.response.data.message)
    }
  }


  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/brands`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => { });;
  }, []);


  const arr = data.map((data, index) => {
    return <></>;
  });

  return (
    <>
      {arr}


      <div>
        <h4 className="text-center">{strings.createAd}</h4>
      </div>

      <div className=" flex justify-center mt-4">
        <div>
          <img className="w-16" src={AdInformation} alt="" />
          <p style={{ color: "#F37521", fontSize: "12px", fontWeight: "bold" }}>
            {strings.adInfo}
          </p>
        </div>
        <div>
          <p
            flex
            className=" bg-red-400 w-36 mt-4  text"
            style={{ height: "1px" }}
          ></p>
        </div>

        <div>
          <img className="w-16" src={Revieworange} alt="" />
          <p style={{ color: "#F37521", fontSize: "14px", fontWeight: "bold" }}>
            {strings.reveiews}
          </p>
        </div>
        <div>
          <p
            flex
            className=" bg-red-400 w-36 mt-4  text"
            style={{ height: "1px" }}
          ></p>
        </div>

        <div>
          <img className="w-16" src={review} alt="" />
          <p className="text-gray-400">{strings.pkg}</p>
        </div>
      </div>

      {/*Section 3*/}
      <div className="container mx-auto px-4 mt-5">
        <div className="bg-white rounded-lg">
          <div className="flex justify-content-between pt-5 p-4">
            <h4>{strings.images}</h4>
            <img className="w-8 cursor-pointer" src={Edit} alt=""
              onClick={() => {
                navigate("/edit-vehicle", {
                  state: {
                    data: vehicleData,
                    manualAd: true,
                    basicInfo: basicInfo,
                  }
                });
              }}
            />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 m-3">
            {vehicleData.imageUrl.length === 0
              ? "No Images"
              : Array.from(new Set(vehicleData.imageUrl.map(file => file))).map((image, index) => (
                <img style={{ height: 120, width: 150 }} src={`${image}`} alt="" key={index} />
              ))}
          </div>

          <div className="flex justify-content-between m-3 px-2">
            <h4>{strings.video}</h4>
          </div>

          <div className="flex justify-content-between m-3 px-2">
            {
              vehicleData.videoUrl && <video controls className="h-80 w-200">
                <source
                  src={vehicleData.videoUrl}
                  type={videoFile.type}
                />
              </video>
            }

          </div>

          <div className="flex justify-content-between m-3 px-2">
            <h4>{strings.basicInfo}</h4>
          </div>

          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.purpose}</p>
            <p>{vehicleData.purpose}</p>
          </div>
          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.category}</p>
            <p>{basicInfo.category}</p>
          </div>
          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.brand}</p>
            <p>{basicInfo.brand}</p>
          </div>
          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.model}</p>
            <p>{basicInfo.model}</p>
          </div>


          <div className="flex flex-wrap justify-content-between m-3 px-2 bg-gray-300">
            <p>{strings.desc}</p>
            <p>{vehicleData.basicInformation.description}</p>
          </div>

          <div className="flex flex-wrap justify-content-between m-3 px-2 bg-gray-300">
            <p>KM</p>
            <p>{vehicleData.basicInformation.KM}</p>
          </div>

          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.price}</p>
            <p>
              {vehicleData.basicInformation.price.price} (
              {vehicleData.basicInformation.price?.currency})
            </p>
          </div>

          <div className="flex justify-content-between m-3 px-2">
            <h4>{strings.location}</h4>
          </div>


          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.country}</p>
            <p>{vehicleData.location.country}</p>
          </div>

          <div className="flex justify-content-between m-2 px-2 mb-3  h-6">
            <p>{strings.distt}</p>
            <p>{vehicleData.location.district}</p>
          </div>

          <div className="flex justify-content-between m-3 px-2 bg-gray-300  h-6">
            <p>{strings.city}</p>
            <p>{vehicleData.location.city}</p>
          </div>

          <div className="flex justify-content-between m-3 mt-3 ">
            <h4>{strings.details}</h4>
          </div>

          <div className="container">
            {vehicleData.basicInformation.details.length !== 0 &&
              vehicleData.basicInformation.details.map((item, index) => (
                <div
                  className={`container flex justify-between items-center ${index % 2 === 0 ? "bg-gray-300" : ""
                    }`}
                >
                  <p>{item?.fieldName}</p>
                  <p>{item?.value}</p>
                </div>
              ))}
          </div>

          {/*internal features*/}
          {vehicleData.internalFeatures.length !== 0 && (
            <div className="mt-5">
              <h4 className="px-3">{strings.internalFeatures}</h4>
            </div>
          )}

          <div className="container mx-auto">
            <div className="container mx-auto mt-3">
              <div className="flex gap-3 flex-wrap">
                {vehicleData.internalFeatures.length !== 0 &&
                  vehicleData.internalFeatures.map((item) => (
                    <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                      <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                      <p
                        className="ps-1 text-center "
                        style={{ fontSize: "14px" }}
                      >
                        {capitalizeFirstLetter(item.fieldName)}

                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/*>External features*/}
          {
            vehicleData.externalFeatures.length !== 0 && (
              <div className="">
                <h4 className="px-3">{strings.extFeatures}</h4>
              </div>
            )
          }

          <div className="container mx-auto">
            <div className="container mx-auto mt-3">
              <div className="flex gap-3 flex-wrap">
                {vehicleData.externalFeatures.length !== 0 &&
                  vehicleData.externalFeatures.map((item) => (
                    <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                      <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                      <p
                        className="ps-1 text-center "
                        style={{ fontSize: "14px" }}
                      >
                        {capitalizeFirstLetter(item.fieldName)}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/*Saftey features*/}
          {
            vehicleData.safetyFeatures.length !== 0 && (
              <div className="">
                <h4 className="px-3">{strings.safetyFeatures}</h4>
              </div>
            )
          }

          <div className="container mx-auto">
            <div className="container mx-auto mt-3">
              <div className="flex gap-3 flex-wrap">
                {vehicleData.safetyFeatures.length !== 0 &&
                  vehicleData.safetyFeatures.map((item) => (
                    <div className="flex gap-2 drop-shadow-lg rounded-xl ">
                      <p className="bg-red-400 w-2 h-2 rounded-full mt-2"></p>

                      <p
                        className="ps-1 text-center "
                        style={{ fontSize: "14px" }}
                      >
                        {capitalizeFirstLetter(item.fieldName)}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/*Body part*/}
          {
            vehicleData.bodyParts.length !== 0 && (
              <div className="flex justify-content-between m-3 ">
                <h4>{strings.bodyParts}</h4>
              </div>
            )
          }

          <div className="container">
            {vehicleData.bodyParts.length !== 0 &&
              vehicleData.bodyParts.map((item, index) => (
                <div
                  className={`container flex justify-between items-center ${index % 2 === 0 ? "bg-gray-300" : ""
                    }`}
                >
                  <p>{capitalizeFirstLetter(item.fieldName)}</p>
                  <p>{item.value}</p>
                </div>
              ))}
          </div>

          <div className="px-3 pt-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              required
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <label class="form-check-label px-3" for="exampleCheck1">
              <div className="flex">
                <p> {strings.accept}</p>{" "}
                <p className="text-orange-400 ps-2 cursor-pointer"
                  onClick={() => {
                    window.open('/terms-of-use', '_blank');
                  }}
                >{strings.adPosting}</p>
              </div>
            </label>
          </div>

          <div className="flex justify-center ">
            <button className="bg-orange-400 p-3 rounded-full text-white sm:w-72 md:w-80 w-64 mb-5 mt-4"
              onClick={() => goNext()}
            >
              {
                isLoading ? <ClipLoader size={12} color="#fff" /> : (
                  isEdit ? strings.update : strings.next
                )
              }

            </button>
          </div>
        </div>
      </div>

      {/*footer*/}
      <Footer strings={strings} />
    </>
  );
};

export default VehiclePreview;
