import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import strings from "../../utils/locals/languages";
import Footer from "../../components/Footer";
import { getApi } from "../../services/getApi";
import NotificationsConvas from "../../components/NotificationsCanvas";

const About = () => {
  const [notificationData, setNotificationData] = useState([])

  useEffect(() => {
    const selectedLanguage = Cookies.get("language")

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage)
    }

  }, [])

  const fetchData = async () => {
    try {
      const response = await getApi("/v1/user/notification");
      setNotificationData(response.data);
    } catch {
      setNotificationData([])
    }
  };


  useEffect(() => {
    fetchData()
  }, [])



  return (
    <>
      <div className="container privacy-policy mt-5">
        <div className="row">
          <div className="col-12">
            <h3 className="font-semibold text-base text-center">{strings.about}</h3>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h5 className="text-[#F37521] text-sm">Intro</h5>
            <p className="text-justify text-xs">
              EGASI is a platform, where user can search ads related to Real Estate, Vehicle and much more. Also user can add their own ads{" "}
            </p>
          </div>
        </div>
      </div>

      <NotificationsConvas notificationData={notificationData} />

    </>
  );
};

export default About;
